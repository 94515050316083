import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
} from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import authentication from "../../../services/authentication";
import { fetch as crossFetch } from "cross-fetch";
import ShareButtons from "../../share/share-buttons-component";
import BooksLoadingContext from "../../../contexts/books-loading/books-loading.context";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";

import BookListChangedContext from "../../../contexts/book-list/book-list-changed.context";
const linesStyle = {
  color: "#333",
  width: "100%",
  p: 0,
  m: "0",
  display: "flex",
  alignContent: "flex-start",
  alignItems: "flex-start",
};
const labelIconStyle = {
  paddingRight: ".5rem",
  opacity: ".9",
};
export default function BookPublishStatus(props) {
  const { book } = props;

  const [postClicked, setPostClicked] = React.useState(false);
  const [unpostClicked, setUnpostClicked] = React.useState(false);
  const [justPosted, setJustPosted] = React.useState(false);

  const [busy, setBusy] = React.useContext(BooksLoadingContext);

  if (!book.publishStatus) {
    book.publishStatus = "notreadytopublish";
  }

  const [, setChanged] = React.useContext(BookListChangedContext);

  const postBook = (deleteClicked) => {
    setBusy(true);
    authentication.getIdToken().then((token) => {
      const data = new URLSearchParams();
      data.append("idToken", token);
      data.append("bookId", book.bookId);
      data.append("delete", Boolean(deleteClicked));

      crossFetch(process.env.REACT_APP_URLBASE + "publish", {
        method: "post",
        body: data,
      })
        .then((res) => {
          if (res.status >= 400) {
            window.openSnackbar(
              "Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later"
            );
          }
          return res.json();
        })
        .then((t) => {
          setBusy(false);
          var error = false;
          if (!t || !t.bookId) {
            window.openSnackbar(
              "Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later"
            );
            error = true;
          }
          if (!error) {
            if (deleteClicked) {
              if (window.openSnackbar) {
                window.openSnackbar("Your book has been removed!!");
              }
              setUnpostClicked(false);
            } else {
              setPostClicked(false);
              book.publishUrl = t.publishUrl; //in case this is the first time the book is being posted
              setJustPosted(true);
            }
          }
          setChanged(true);
          setBusy(false);
        })
        .catch(function (e) {
          window.openSnackbar(
            "Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later"
          );
          console.log("Got an error back" + e);
          setBusy(false);
        });
    });
  };
  function getDateAsString(utc) {
    if (isNaN(utc)) return "-";

    const date = new Date(utc);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  }
  function getURL(partial) {
    return process.env.REACT_APP_WRITER_BOOK_URLBASE + partial;
  }
  function showPostedDetails() {
    return (
      <>
        <Typography
          sx={{ width: "100%", textAlign: "center", fontSize: "1.2rem" }}
          variant="notice"
        >
          LIVE!!
        </Typography>
        <Typography component="div" sx={{ width: "100%", textAlign: "center" }}>
          Your book is live on MeetNewBooks.com
          <Typography
            sx={{ width: "100%", textAlign: "center", padding: ".5rem 1rem" }}
            noWrap
          >
            <a
              href={getURL(book.publishUrl)}
              target="_blank"
              rel="noreferrer nofollow"
            >
              {book.title} by {book.authorName}
            </a>
          </Typography>
        </Typography>

        <Typography
          variant="notice"
          sx={{ width: "100%", textAlign: "center", paddingTop: "1rem" }}
        >
          SHARE IT WITH YOUR FRIENDS, FANS AND FAMILY
        </Typography>
        <ShareButtons url={getURL(book.publishUrl)} title={book.title} />
      </>
    );
  }

  return (
    <Card
      sx={{
        maxWidth: "md",
        display: "flex",
        p: "1rem 0",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
      elevation={5}
    >
      {book.publishStatus === "published" && (
        <>
          {showPostedDetails()}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              flexWrap: "wrap",
              padding: "0 1rem",
            }}
          >
            <Typography
              variant="caption"
              sx={{ width: "100%", textAlign: "right" }}
            >
              {" "}
              Posted on {getDateAsString(book.publishedTimestamp)}
            </Typography>
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: "none", color: "#333" }}
              onClick={() => setUnpostClicked(true)}
            >
              Un-Post/Remove
            </Button>
          </Box>
        </>
      )}
      {book.publishStatus === "readytopublish" && (
        <>
          <Typography variant="notice">Ready to SUBMIT!!</Typography>

          <Typography
            sx={{
              width: "100%",
              fontSize: ".9rem",
              textAlign: "left",
              p: 1,
              lineHeight: "2rem",
            }}
          >
            Submitting will make your book available to readers on
            MeetNewBooks.com
            <br />
            After you submit:
            <br /> &nbsp;&bull; &nbsp; Your book will be <em>visible</em> on
            MeetNewBooks.com for as long as you like.
            <br /> &nbsp;&bull; &nbsp; Your book will be <em>promoted</em> on
            MeetNewBooks.com alongside your selected 'similar' books for one
            week.
            <br /> &nbsp;&bull; &nbsp; After one week, you can select another
            'similar book'
          </Typography>
          <Button
            variant="contained"
            color="action"
            onClick={() => setPostClicked(true)}
          >
            Click to SUBMIT To MeetNewBooks.com
          </Button>
        </>
      )}
      {book.publishStatus === "notreadytopublish" && (
        <>
          <Typography
            sx={{ width: "100%", fontSize: "1.3rem", textAlign: "center" }}
            variant="notice"
          >
            Not Ready to Submit
          </Typography>
          <Box sx={{ width: "100%", p: "0 1rem" }}>
            <Typography
              sx={{
                width: "100%",
                fontSize: "1rem",
                textAlign: "left",
                p: ".5rem 0",
                lineHeight: "1.5rem",
              }}
            >
              Steps to submit your book:
            </Typography>

            {bulletedLine(
              "Fill in the book details- cover, title, synopsis and Amazon ASIN."
            )}
            {bulletedLine(
              "Select one or more mainstream, popular books that your book is similar to."
            )}

            <Typography
              sx={{
                width: "100%",
                fontSize: "1rem",
                textAlign: "left",
                p: ".5rem 0",
                lineHeight: "1.5rem",
              }}
            >
              After you submit:
            </Typography>
            {bulletedLine(
              "Your book will be posted on MeetNewBooks.com for as long as you like."
            )}
            {bulletedLine(
              "Your book will be promoted on MeetNewBooks.com for your selected 'popular' books for one week."
            )}
            {bulletedLine(
              "After one week, you can select another 'popular' book "
            )}
          </Box>
        </>
      )}
      {postClicked && (
        <Dialog maxWidth="sm" open={postClicked}>
          <DialogTitle>Submit Your Book to MeetNewBooks?</DialogTitle>
          <DialogContent>
            <Typography>
              This will submit your book to MeetNewBooks.com, creating a unique
              book link for you and your readers
            </Typography>
            <DialogActions>
              <Button
                disabled={busy}
                variant="outlined"
                onClick={() => setPostClicked(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={busy}
                variant="contained"
                onClick={() => postBook(false)}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
      {unpostClicked && (
        <Dialog maxWidth="sm" open={unpostClicked}>
          <DialogTitle>Remove your book from MeetNewBooks?</DialogTitle>
          <DialogContent>
            <Typography>
              This will hide your book from readers at MeetNewBooks.com.{" "}
            </Typography>
            <DialogActions>
              <Button
                disabled={busy}
                variant="outlined"
                onClick={() => setUnpostClicked(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={busy}
                variant="contained"
                onClick={() => postBook(true)}
              >
                Remove
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
      {justPosted && (
        <Dialog maxWidth="sm" open={justPosted}>
          {/* <DialogTitle>Yay! Your book has been posted</DialogTitle> */}
          <DialogContent
            sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
          >
            {showPostedDetails()}
          </DialogContent>
          <DialogActions>
            <Button
              disabled={busy}
              variant="outlined"
              onClick={() => {
                setJustPosted(false);
                setChanged(true);
                //history.goBack()
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* </Box> */}
    </Card>
  );
}

function bulletedLine(text) {
  return (
    <Typography component="div" sx={linesStyle}>
      <LabelImportantIcon style={labelIconStyle} color="primary" />
      <Typography sx={{ fontSize: ".9rem" }}>{text}</Typography>
    </Typography>
  );
}
