import * as React from 'react';
import {
  Typography,
  Box,
  Button,
  CircularProgress,Breadcrumbs
} from "@mui/material";
import 'react-quill/dist/quill.snow.css';
import { useHistory } from 'react-router';

import { ArrowBack} from '@mui/icons-material';
import BookSampleEdit from './book-sample-edit.component';
import CurrentBookContext from '../../../../contexts/current-book/current-book.context';
import bookService from '../book-service';



export default function BookSample(props) {
  const {bookId}=props;
  const [book,setBook]= React.useContext(CurrentBookContext);
  const [editing, setEditing] = React.useState(false)
  const history = useHistory();

  
  
  React.useEffect(()=>{
    const onFetch=(b)=>{
      if(!b.sample){
        b.sample={}
      }
      setBook(b);
      if(!book.publishStatus==='published'){
        setEditing(!b.sample || !b.sample.chapter );
      }
      
        
    }
    if(bookId){
      bookService.fetchBook(bookId,onFetch)
    }
  },[bookId])
  if (!book || !book.sample) return <Box><CircularProgress /></Box>
  const published = Boolean(book && book.publishStatus==='published');
  return (<Box >
    {/* <Button startIcon={<ArrowBack />} onClick={cancelClicked}>Back</Button> */}
    <Breadcrumbs aria-label="breadcrumb" style={{marginBottom:'.5rem'}}>
     
     <Button sx={{textTransform:'none'}} onClick={()=>history.push("/mybooks")}> My Books </Button>
     <Button sx={{textTransform:'none'}} onClick={()=>history.push("/bookcard/"+bookId)}> {book.title?book.title:'Untitled'} </Button>
     <Typography variant="formValue" > Excerpt </Typography>
     
    </Breadcrumbs>

    <Box sx={{ margin: '1rem 0', maxWidth: 'lg', display: 'flex', justifyContent: 'flex-start' }}>
      <Button variant="contained" color="primary" sx={{ textTransform: 'none' }} onClick={() => history.push("/bookcard/" + bookId)} startIcon={<ArrowBack />}> 
        Done with Excerpt </Button>
    </Box>
    {published && (
      <Typography variant="notice" sx={{display:'block'}}>This book has been posted to meetnewbooks.com. <br/>To make changes, you must first un-post it from meetnewbooks </Typography>
    )}
    <Typography className="formHeader" sx={{ color: 'primary.main'}} variant="h6">Enter an excerpt from your book</Typography>

    {!editing && (
        <Box justifyContent='center' >

            <Box sx={{ display: 'flex', justifyContent: 'flex-end',p:'1rem 0' }}>
              <Button variant="contained" disabled={published} onClick={() => setEditing(true)}>Edit Sample</Button>
            </Box>
             <Typography variant="formValue" sx={{border:'1px solid #EEE',borderRadius:'8px',minHeight:'300px'}} ><span className="editorHtmlSpan"
              dangerouslySetInnerHTML={{ __html: book.sample.chapter ? book.sample.chapter :
                (published?'':'<p>Click the Edit button to enter an excerpt....</p>')
                }} /> </Typography>

        </Box>
    )}
 {editing && (<BookSampleEdit editing={editing} setEditing={setEditing}/>)}
  </Box>)

}