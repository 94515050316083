import React, { useState } from 'react';
import { fetch as crossFetch } from 'cross-fetch';
import {
    TextField, Autocomplete,createFilterOptions, Box
}
    from "@mui/material";
    
export default function TagsAutoComplete({value, onChange,helperText,label}){
    const [tags,setTags]=useState([]);
    const filterOptions = createFilterOptions({
        matchFrom: 'any',
       // stringify: (option) => option.title,
      });
    React.useEffect(()=>{
        const url = process.env.REACT_APP_URLBASE + "books/getUserBookTags";
        crossFetch(url)
        .then(res => {
            return res.json();
        })
        .then(
            (data) => {
                if (data) {
                    setTags(data)
                }

            }
        ).catch(function (e) {
            console.log("Got an error back" + e)
            window.gtag('event', 'exception', {
                'description': e + ' url ' + url,
                'fatal': false   // set to true if the error is fatal

            });
        }).finally(() => {

        });
    },[]);

    return <Autocomplete
    multiple
    freeSolo
    id="tags-outlined"
    options={tags}
    filterOptions={filterOptions}
    // defaultValue={["foo", "bar"]}
    onChange={(e,v) => {
        onChange(v);
    }}
    renderOption={((props, option, { selected })=>{
        return <li {...props}>
            <Box sx={{border:'1px solid #333',padding:'.25rem .5rem', fontSize:'.8rem', borderRadius:'18px', backgroundColor:'primary.main', color:'primary.contrastText'}}>{option}</Box>
        </li>    
    })}
     defaultValue={[]} value={value ? value : []}
    renderInput={(params) => (
        <TextField
            {...params}
            label={label}
            variant="outlined"
            size="small"
            helperText={helperText}
            
        />
        
    )}
    ChipProps={{
        color:'tags'
    }}
/>
}