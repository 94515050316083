import { Typography, Box, MenuItem, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import DateSelector from "./date-picker.component";
import authentication from "../../../services/authentication";
import { fetch as crossFetch } from "cross-fetch";
import CurrentBookContext from "../../../contexts/current-book/current-book.context";
import BooksLoadingContext from "../../../contexts/books-loading/books-loading.context";
import HelpPopover from "../../help/help-popover";

export default function BookFormatEdit(props) {
  const [book, setBook] = useContext(CurrentBookContext);
  const [, setBooksLoading] = useContext(BooksLoadingContext);
  const { setEditing } = props;
  const format = book.format ? book.format : {};

  const [selectedFormat, setSelectedFormat] = useState(format.bookFormat);
  const [asin, setAsin] = useState(format.asin);

  const handleFormatChange = (e) => {
    setSelectedFormat(e.target.value);
  };

  const saveBookFormat = () => {
    if (!selectedFormat) {
      window.openSnackbar("Please select a book format");
      return;
    }
    if (!asin || asin === "") {
      window.openSnackbar("Please enter the ASIN");
      return;
    }

    const data = new URLSearchParams();
    for (const pair of new FormData(document.getElementById("formatForm"))) {
      data.append(pair[0], pair[1]);
    }
    setBooksLoading(true);
    authentication.getIdToken().then((token) => {
      data.append("idToken", token);
      //   data.append("delete", Boolean(deleteClicked));

      crossFetch(process.env.REACT_APP_URLBASE + "saveWriterBookFormat", {
        method: "post",
        body: data,
      })
        .then((res) => {
          if (res.status >= 400) {
            window.openSnackbar(
              "Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later"
            );
          }
          return res.json();
        })
        .then((t) => {
          setBooksLoading(false);
          var error = false;
          if (!t || !t.bookId) {
            window.openSnackbar(
              "Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later"
            );
            error = true;
          }
          if (!error) {
            if (window.openSnackbar) {
              window.openSnackbar("Your changes have been saved");
              setEditing(false);
            }
            setBook(t);
            //setWriterProfile(writerProfile);
          }
        })
        .catch(function (e) {
          window.openSnackbar(
            "Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later"
          );
          console.log("Got an error back" + e);
          setBooksLoading(false);
        });
    });
  };
  const textStyle = { padding: "1.5rem .5rem 0 0" };

  if (!book) return null;
  const saveButtons = (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button variant="outlined" onClick={() => setEditing(false)}>
        Cancel
      </Button>{" "}
      &nbsp;
      <Button color="action" variant="contained" onClick={saveBookFormat}>
        Save
      </Button>
    </Box>
  );
  return (
    <Box
      sx={{
        m: "0",
        p: "0",
        borderBottom: "1px solid",
        borderColor: "primary.main",
      }}
    >
      {saveButtons}
      <Box sx={{ maxWidth: "md", margin: "auto" }}>
        <Typography variant="h6" color="#333" sx={{ p: "1rem 0" }}>
          Book Format and ASIN are required fields
        </Typography>
        <form id="formatForm">
          <TextField
            select
            label={requiredLabel("Book Format")}
            value={selectedFormat}
            size="small"
            onChange={handleFormatChange}
            sx={{ width: "10rem", m: ".5rem 0" }}
          >
            <MenuItem key={0} value="ebook">
              E-BOOK
            </MenuItem>
            <MenuItem key={1} value="paperback">
              PAPERBACK
            </MenuItem>
            <MenuItem key={2} value="hardcover">
              HARDCOVER
            </MenuItem>
          </TextField>
          <Box id={"box-" + format} sx={{ width: "100%" }}>
            {/* <form id={'form-'+format}>  very stange but defaultValue stops working with a form here*/}

            <input type="hidden" name="bookId" value={book.bookId} />
            <input type="hidden" name="bookFormat" value={selectedFormat} />

            <Box sx={textStyle}>
              <TextField
                size="small"
                sx={{ width: "10rem" }}
                type="text"
                name="asin"
                label={requiredLabel("ASIN (Amazon)")}
                InputLabelProps={{ shrink: true }}
                defaultValue={format.asin}
                onChange={(e) => setAsin(e.currentTarget.value)}
                inputProps={{ maxLength: 20 }}
              />
              <div style={{ margin: ".25rem 1rem 0 0" }}>
                <HelpPopover topic="asin" alternateText={"Help With ASIN"} />
              </div>
            </Box>
            <Box sx={textStyle}>
              <TextField
                size="small"
                sx={{ width: "10rem" }}
                name="isbn13"
                label="ISBN 13"
                defaultValue={format.isbn13}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 20 }}
              />
            </Box>

            <Box sx={textStyle}>
              <TextField
                size="small"
                sx={{ width: "5rem" }}
                label="# Pages"
                type="number"
                name="pageCount"
                InputLabelProps={{ shrink: true }}
                defaultValue={format.pageCount}
                inputProps={{ min: 0, max: 9999 }}
              />
            </Box>
            <Box sx={{ width: "100%", padding: ".5rem" }}>
              <Typography variant="caption" sx={{ color: "#666" }}>
                Published Date
              </Typography>
              <DateSelector
                publishedDay={format.publishedDay}
                publishedMonth={format.publishedMonth}
                publishedYear={format.publishedYear}
              />
            </Box>
          </Box>
        </form>
      </Box>
      {saveButtons}
      <br />
    </Box>
  );
  function requiredLabel(label) {
    return (
      <span>
        {label} <span style={{ color: "red" }}>*</span>
      </span>
    );
  }
}
