import React from 'react';
import { useState } from 'react';
import { Box } from '@mui/material';
import {useHistory } from 'react-router-dom'
import StaticContent from './static-content.component';


export default function StaticContentDirect (props){
    const [contactOpen,setContactOpen] = useState(props.contactOpen);
    const {contentType,locationState} = props;
    const history=useHistory();
    const onClose =()=>{
        setContactOpen(false);
        if(locationState && locationState.goBack) {
            history.goBack();
        }
        else {
            history.push("/")
        }
    }
    return (
        <Box sx={{height:'80vh'}}>
            <StaticContent open={contactOpen} contentType={contentType} handleClose={onClose}/>
        </Box>

    )
}