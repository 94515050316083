import React, { useState } from 'react';
import { useContext } from 'react';
import { fetch as crossFetch } from 'cross-fetch';
import {
    Box,
    Card, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions
}
    from "@mui/material";

import BookImage from '../../../book-image/book-image.component';
import SearchBooks from '../../../search/searchbooks';
import PopularBookReviewDialog from './popular-book-review-dialog.component';
import authentication from '../../../../services/authentication';
import BookListChangedContext from '../../../../contexts/book-list/book-list-changed.context';
import HelpPopover from '../../../help/help-popover';
import BookPromoStatus from '../../mybooks/book-promo-status.component';




export default function PopularBookCard(props) {
    const { similarBook, book, onChange } = props;


    const [popularBook, setPopularBook] = useState(null)
    const [,setChanged]=useContext(BookListChangedContext)
    const [selectedPopularBookId, setSelectedPopularBookId] = useState(null)
    const [removeClicked, setRemoveClicked] = useState(false);

    const onPopularBookSelected = (popularBookId) => {
        authentication.getIdToken().then((token) => {
            const data = new FormData();
            data.append("idToken", token);
            data.append("bookId", book.bookId);
            data.append("popularBookId", popularBookId);

            crossFetch(process.env.REACT_APP_URLBASE + "checkSimilarBook", {
                method: 'post',
                body: data
            }).then(res => {
                if (res.status >= 400) {
                    window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
                }
                return res.json();
            }).then((t) => {

                var error = Boolean(!t.success);

                if (error) {
                    console.log("Error")
                    const message= t.previousDate? "You already selected this book on "+ t.previousDate +". Please select a different book":
                        "Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later";
                    window.openSnackbar(message);
                    error = true;
                }
                if (!error) {
                    setSelectedPopularBookId(popularBookId);

                }
                setChanged(true);

            }
            ).catch(function (e) {
                window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
                console.log("Got an error back" + e)
                // setBusy(false);
            });
        });
    }

    const removeSimilarBook = () => {

        //setBusy(true);

        setRemoveClicked(false);
        authentication.getIdToken().then((token) => {
            const data = new FormData();
            data.append("idToken", token);
            data.append("bookId", book.bookId);
            data.append("popularBookId", similarBook.popularBookId);

            crossFetch(process.env.REACT_APP_URLBASE + "removeSimilarBook", {
                method: 'post',
                body: data
            }).then(res => {
                if (res.status >= 400) {
                    window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
                }
                return res.json();
            }).then((t) => {

                var error = t != 1;

                if (error) {
                    console.log("Error")
                    window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
                    error = true;
                }
                if (!error) {
                    //populatePopularBookIds(t);
                    if (window.openSnackbar) {
                        window.openSnackbar("Removed Similar Book");
                    }
                    onChange();

                }
                setChanged(true);

            }
            ).catch(function (e) {
                window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
                console.log("Got an error back" + e)
                // setBusy(false);
            });
        });
    }
    React.useEffect(() => {

        if (similarBook.popularBookId) {
            // setBooksLoading(true);
            async function getBooks() {
                const url = process.env.REACT_APP_URLBASE + "books/book?type=book&id=" + similarBook.popularBookId;
                crossFetch(url)
                    .then(res => {
                        return res.json();
                    })
                    .then(
                        (data) => {
                            if (data.id) {
                                setPopularBook(data)
                            }

                        }
                    ).catch(function (e) {
                        console.log("Got an error back" + e)
                        window.gtag('event', 'exception', {
                            'description': e + ' url ' + url,
                            'fatal': false   // set to true if the error is fatal

                        });
                    }).finally(() => {

                    });
            }
            getBooks();
        }
        else {
            setPopularBook({
                //id: popularBookId,
                title: '',

            })
        }

    }, [similarBook.popularBookId, selectedPopularBookId,]);

    if (!similarBook) return null;

    const schedule = similarBook.schedule;


    return (
        <Card sx={{ width: '100%', maxWidth: 'md', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', p: '0rem .5rem 0rem .5rem' }} >
            <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-start', width: '100%', flexWrap: 'nowrap', alignContent: 'center' }}>
                <Typography sx={{ fontSize: '30px', fontStyle: 'italic', p: '0 .25rem', textAlign: 'center' }}>#{similarBook.similaritySequence}</Typography>
            </Box>
            {!similarBook.popularBookId
                && (
                    <Box sx={{ p: '0 0 3rem 0', minHeight: '150px', display: 'flex', justifyContent: 'center', width: '100%', flexWrap: 'wrap', alignContent: 'flex-start' }}>
                        <Typography sx={{ width: '100%', textAlign: 'center', color: '#333' }}>
                            Select a similar book <HelpPopover topic="discoverability"/>
                        </Typography>
                        <Box sx={{ minWidth: '280px', width: '100%', maxWidth: '40ch' }}>
                            <SearchBooks setSelectedBook={onPopularBookSelected} />
                        </Box>
                        <Box sx={{ width: '100%', }}>
                            <Typography component="p" variant="caption" sx={{ width: '100%', textAlign: 'center', color: '#333', maxWidth: '50ch', margin: 'auto', p: 1 }}>
                                Use the search box above to select a 'popular' book - one that your target readers will likely be familiar with, and one that you think is most similar to your book.
                            </Typography>
                        </Box>

                    </Box>
                )}

            {similarBook.popularBookId && popularBook && (
                <Box sx={{ width: '100%', display: 'flex', flexWrap: 'nowrap', justifyContent: 'center' }}>
                    <Box>
                        <BookImage book={popularBook} hideSeriesImage={true} nolinks={true} />
                        <Typography noWrap sx={{ maxWidth: { xs: '130px', sm: '150px', md: '200px' }, fontSize: '.8rem', p: '1rem 0', fontWeight: 'bold', textAlign: 'center' }}>
                            {popularBook.title} 
                        </Typography>

                    </Box>
                    <Box sx={{ p: '0 2rem ', display: 'flex', justifyContent: 'center', width: '100%', flexWrap: 'wrap', alignContent: 'space-between' }}>
                        <BookPromoStatus book={similarBook} index={1}/>
                        {/* {schedule.expired && (
                            <Box sx={{textAlign:'center',backgroundColor:'#ffee99',p:1}}>
                                <Typography variant="notice" sx={{color:'action.main', borderRadius:'8px', backgroundColor:'#ffee99', padding:'2px 5px'}}>
                                    Expired!! Time to renew! </Typography>  
                                    <Typography  sx={{color:'#333'}}>
                                 You can swap <b>{popularBook.title}</b> with another book. Start Typing into the search book to select a new 'popular' book.
                                </Typography>    
                           
                                <Typography  sx={{color:'#333'}}>
                                <b>{book.title}</b> is no longer being recommended to readers when they view <b>{popularBook.title}</b> 
                                <br/>( <b>{schedule.reservationStartDate}</b> to <b>{schedule.reservationEndDate}</b>)
                                </Typography>  
                                
                            </Box>
                        )}

                        {schedule.current && schedule.status==='confirmed' && (
                            <Box sx={{textAlign:'center'}}>
                                <Typography  sx={{color:'#333'}}>
                                <b>{book.title}</b> is being recommended to readers when they view <b>{popularBook.title}</b>.
                                 <br/>(<b>{schedule.reservationStartDate}</b> to <b>{schedule.reservationEndDate}</b>)
                                </Typography>  
                                  
                            
                            </Box>
                        )}
                        {!schedule.current && !schedule.expired && schedule.status==='confirmed' && (
                            <Box sx={{textAlign:'center'}}>
                                <Typography  sx={{color:'#333'}}>
                                <b>{book.title}</b> will be recommended to readers when they view <br/> <b>{popularBook.title}</b> <br/>
                                  during the dates <b>{schedule.reservationStartDate}</b> to <b>{schedule.reservationEndDate}</b>
                                </Typography>  
                                  
                            
                            </Box>
                        )}
                        
                        {schedule.status==='tentative' && (
                            <Box sx={{textAlign:'center'}}>
                                <Typography variant="notice">Tentative</Typography>  
                                <Typography  component="p" sx={{color:'#333'}}>
                                <b>{book.title}</b> will be recommended to readers when they view <b>{popularBook.title}</b>
                                 {' '} from <b>{schedule.reservationStartDate}</b> to <b>{schedule.reservationEndDate}</b>
                                </Typography>  
                                <Typography  component="p" sx={{color:'#333'}}>
                                 We will confirm the exact dates once you <em>submit</em>  <b>{book.title}</b> on MeetNewBooks.com
                                </Typography>    
                            
                            </Box>
                        )} */}
                         <Box sx={{ p: 1, display: 'flex', justifyContent: 'center', width: '100%', flexWrap: 'nowrap', alignContent: 'center', columnGap: '1rem' }}>
                            
                            {schedule.expired?<Box sx={{ minWidth: '280px', width: '100%', maxWidth: '40ch' }}>
                            <SearchBooks setSelectedBook={onPopularBookSelected} />
                        </Box>
                            :<><Button size="small" variant="contained" sx={{ textTransform: 'none', fontSize: '.8rem' }} onClick={() => setSelectedPopularBookId(similarBook.popularBookId)} >Edit</Button>
                            <Button size="small" variant="contained" sx={{ textTransform: 'none', fontSize: '.8rem' }} onClick={() => setRemoveClicked(true)} >Remove  </Button>
                            </>
                            } 
                        </Box>
                    </Box>

                </Box>
            )}
            {/* <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end', width: '100%', flexWrap: 'nowrap', alignContent: 'center' }}>
                <IconButton sx={{ border: '0px solid', color: 'linkColor.main' }} disabled={Boolean(index === 1 || popularBookId === -1)} onClick={() => moveUp(index)}><ArrowUpwardIcon /></IconButton>
                <IconButton sx={{ border: '0px solid', color: 'linkColor.main' }} disabled={Boolean(index === MAX || popularBookId === -1)} onClick={() => moveDown(index)}><ArrowDownwardIcon /></IconButton>
            </Box> */}
            {selectedPopularBookId && (
                <PopularBookReviewDialog book={book} popularBookId={selectedPopularBookId} onSave={(sBook) => {

                    setSelectedPopularBookId(null);
                    onChange();


                }} similarBook={similarBook} />
            )}
            {removeClicked && (
                <Dialog open={removeClicked}>
                    <DialogTitle>
                        Confirm
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            Remove <b>{popularBook.title}</b> as a similar book?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setRemoveClicked(false)}> Cancel</Button>
                        <Button color="action" variant="contained" onClick={() => removeSimilarBook()}>Yes, Remove</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Card>
    );
}
