import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';

export default function DateSelector(props) {
    const {publishedDay,publishedMonth, publishedYear}= props;
    //console.log("publishedDay"+publishedMonth)

    const [month, setMonth] = React.useState('');
    const [day, setDay] = React.useState('');
    const [year, setYear] = React.useState('');
    
    React.useEffect(()=>{
        setMonth(publishedMonth?publishedMonth:'');
        setDay(publishedDay?publishedDay:'');
        setYear(publishedYear?publishedYear:'')
    },[])

    //console.log("month:"+month);
    const getMonths = () => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const menus = [];
        for (var i = 0; i < 12; i++) {
            menus.push(<MenuItem key={i} value={months[i]}>{months[i]}</MenuItem>)
        }
        return menus;
    }
    const getDays = () => {

        const menus = [];
        for (var i = 1; i < 32; i++)
            menus.push(<MenuItem key={i} value={i}>{i}</MenuItem>)

        return menus;
    }
    const getYears = () => {
        const currentYear = new Date().getFullYear();
        const menus = [];
        for (var i = currentYear; i > 1900; i--)
            menus.push(<MenuItem key={i} value={i}>{i}</MenuItem>)

        return menus;
    }

    const handleDayChange = (event) => {
        setDay(event.target.value);
    };
    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };
    const handleYearChange = (event) => {
        setYear(event.target.value);
    };
    return (
        <Box sx={{ minWidth: 120 ,p:'.5rem 0'}}>
            <input type="hidden" name="publishedMonth" value={month} />
            <input type="hidden" name="publishedDay" value={day} />
            <input type="hidden" name="publishedYear" value={year}/>
            <TextField
                select
                label="Month"
                value={month}
                size="small"
                onChange={handleMonthChange}
                //   helperText="Please select your currency"
                sx={{ width: '5rem' ,m:'0 2px 0 0'}}
            >
                {getMonths()}
            </TextField>
            <TextField
                
                select
                label="Day"
                value={day}
                size="small"
                onChange={handleDayChange}
               
                sx={{ width: '4rem',m:'0 2px 0 0' }}
            >
                {getDays()}
            </TextField>
            <TextField
                select
                label="Year"
                value={year}
                size="small"
                onChange={handleYearChange}
               
                sx={{ width: '5rem' }}
            >
                {getYears()}
            </TextField>
        </Box>
    );
}
