import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  TextField,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import WriterProfileContext from "../../contexts/writer-profile/writer-profile.context";
import authentication from "../../services/authentication";
import { DialogContent } from "@material-ui/core";
import BooksLoadingContext from "../../contexts/books-loading/books-loading.context";
import bookService from "../writer-book/book/book-service";

export default function VerifyEmail({ user }) {
  const [code, setCode] = useState("");
  const [booksLoading, setBooksLoading] = React.useContext(BooksLoadingContext);
  const [writerProfile, setWriterProfile] = useContext(WriterProfileContext);
  useEffect(() => {
    console.log("VerifyEmail useEffect", { user });
  }, [user]);
  const verifyToken = (codeToken) => {
    authentication.getIdToken().then((token) => {
      postVerifyRequest(token, codeToken);
    });
  };
  const postVerifyRequest = (token, codeToken) => {
    setBooksLoading(true);
    const data = new URLSearchParams();
    data.append("idToken", token);
    data.append("code", codeToken.trim());

    fetch(process.env.REACT_APP_URLBASE + "verifyToken", {
      method: "post",
      body: data,
    })
      .then((res) => {
        setBooksLoading(false);
        if (res.status >= 400) {
          window.openSnackbar(
            "Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later"
          );
        }
        return res.json();
      })
      .then((t) => {
        //setBusy(false);

        var error = false;
        setCode("");
        if (t === 0) {
          window.openSnackbar(
            "Sorry, the code you entered is either invalid or expired"
          );
          error = true;
        }
        if (!error) {
          bookService.fetchProfile(token, setWriterProfile);
          if (window.openSnackbar) {
            window.openSnackbar("Thank you. Your email has been verified");
          }
        }
      })
      .catch(function (e) {
        window.openSnackbar(
          "Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later"
        );
        console.log("Got an error back" + e);
        setBooksLoading(false);
      });
  };
  const resendVerificationEmail = (token) => {
    setBooksLoading(true);
    const data = new URLSearchParams();
    data.append("idToken", token);

    fetch(process.env.REACT_APP_URLBASE + "resendVerificationCode", {
      method: "post",
      body: data,
    })
      .then((res) => {
        setBooksLoading(false);
        if (res.status >= 400) {
          window.openSnackbar(
            "Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later"
          );
        }
        return res.json();
      })
      .then((t) => {
        //setBusy(false);

        var error = false;
        if (t !== 1) {
          window.openSnackbar(
            "Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later"
          );
          error = true;
        }
        if (!error) {
          bookService.fetchProfile(token, setWriterProfile);
          if (window.openSnackbar) {
            window.openSnackbar("Email sent");
          }
        }
      })
      .catch(function (e) {
        window.openSnackbar(
          "Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later"
        );
        console.log("Got an error back" + e);
        setBooksLoading(false);
      });
  };
  const sendVerificationEmail = () => {
    authentication.getIdToken().then((token) => {
      resendVerificationEmail(token);
    });
  };
  const onChangeCode = (value) => {
    setCode(value.trim());
    if (value.trim().length == 5) {
      verifyToken(value.trim());
    }
  };
  if (user && !writerProfile.emailVerified) {
    return (
      <Dialog open={!writerProfile.emailVerified} dis>
        <DialogTitle>Verify Email</DialogTitle>
        <DialogContent>
          <Typography>
            We need to verify your email address. We've sent an email to{" "}
            <b>{user.email}</b>
          </Typography>
          <Typography>
            If you can’t find the email, check your spam/junk folder. And please
            mark this address as ‘not spam.’ If the email isn’t in your
            spam/junk folder, either, you should look in the Promotions tab.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", p: "1rem 0" }}>
            <Typography>
              {booksLoading && <CircularProgress />}
              <TextField
                id="token"
                inputProps={{ maxLength: 10 }}
                sx={{ width: "20ch" }}
                placeholder="Enter or paste code"
                defaultValue={code ? code : ""}
                onChange={(e) => onChangeCode(e.target.value)}
              ></TextField>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={sendVerificationEmail}>
            {" "}
            Re-send Verification Email
          </Button>
          <Button variant="contained" onClick={() => verifyToken(code.trim())}>
            {" "}
            Validate Code{" "}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
