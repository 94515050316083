
import * as React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
const ratingStyles = {
    selected: {
        backgroundColor: '#769650',
        borderColor: '#ddd',
        color: '#fff',
        minWidth: '18ch'
    },
    default: {
        backgroundColor: '#fff',
        borderColor: '#455964',
        color: '#455964',
        minWidth: '18ch'
    }


}
const ratingStyle = {
    borderRadius: '24px',
    margin: '1rem 1rem 0 0',
    fontSize: '.8rem'

}
export default function QualitativeRating({ defaultValue, onChange, elements }) {

    const [rating, setRating] = React.useState(defaultValue);
    const allRefs = React.useRef([]);
    React.useEffect(() => {
        refreshButtons(defaultValue);
        setRating(defaultValue)
    }, [defaultValue]);



    return <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        
        {elements && elements.map((e, i) => {
            return <Button size="small" key={i} value={e.value} ref={el => allRefs.current[e.value] = el} variant="outlined" sx={ratingStyle} onClick={handleClick}
                endIcon={e.value === rating ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}
            >{e.label}</Button>
        })}
        <Typography variant="caption" sx={{ width: '100%', display: 'block',textAlign:'right',padding:'.5rem 2rem' }}>Click or tap to select/deselect</Typography>

    </Box>

    function handleClick(e) {
        const selected = e.currentTarget.value;
        updateState(selected);

        return false;

    }

    function updateState(selected) {
        selected = parseInt(selected);
        if (selected === rating) { //deselected
            onChange && onChange(null);
            refreshButtons(null);
            setRating(null);
        }
        else {
            onChange && onChange(selected);
            refreshButtons(selected);
            setRating(selected);
        }
    }

    function refreshButtons(selected) {

        const size = allRefs.current.length;
        for (let k = 0; k < size; k++) {
            //const ref = allRefs[keys[k]];
            const obj = allRefs.current[k];
            if (obj) {
                if (parseInt(obj.value) != selected) {
                    Object.assign(obj.style, ratingStyles.default);
                }
                else {
                    Object.assign(obj.style, ratingStyles.selected);
                }
            }
        }
    }
}

