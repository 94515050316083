import {
  Typography,
  Box,
  Button,
  Card,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { fetch as crossFetch } from "cross-fetch";
import authentication from "../../../services/authentication";
import CurrentBookContext from "../../../contexts/current-book/current-book.context";
import AddIcon from "@mui/icons-material/Add";
import { useHistory, useLocation } from "react-router";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import BooksLoadingContext from "../../../contexts/books-loading/books-loading.context";
import BookListChangedContext from "../../../contexts/book-list/book-list-changed.context";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import HelpPopover from "../../help/help-popover";
import BookPromoStatus from "./book-promo-status.component";
import { useTheme } from "@emotion/react";

export default function WriterBookList(props) {
  const [books, setBooks] = useState();
  const [, setCurrentBook] = useContext(CurrentBookContext);
  const [numBooks, setNumBooks] = useState();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [page, setPage] = useState(
    query.get("page") && !isNaN(query.get("page"))
      ? parseInt(query.get("page"))
      : 1
  );
  const [sortBy, setSortBy] = useState(
    query.get("sort") ? query.get("sort") : "mostRecent"
  );
  const [filterBy, setFilterBy] = useState(
    query.get("filter") ? query.get("filter") : "all"
  );

  const [bookListChanged, setBookListChanged] = useContext(
    BookListChangedContext
  );
  const [booksloading, setBooksLoading] = useContext(BooksLoadingContext);

  const history = useHistory();
  //TODO move this to a POST request
  const fetchBooks = (token) => {
    setBooksLoading(true);
    const url =
      process.env.REACT_APP_URLBASE +
      "getWriterBooks?idToken=" +
      token +
      "&sort=" +
      sortBy +
      "&filter=" +
      filterBy +
      "&page=" +
      (page ? page : 1);
    crossFetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setBooks(data.books);
        setNumBooks(data.numBooks);
        setBooksLoading(false);
        const newUrl =
          "/mybooks?sort=" + sortBy + "&filter=" + filterBy + "&page=" + page;
        history.push(newUrl);
      })
      .catch(function (e) {
        console.log("Got an error back" + e);
        window.gtag("event", "exception", {
          description: e + " url " + url,
          fatal: false, // set to true if the error is fatal
        });
        setBooksLoading(false);
      });
  };

  React.useEffect(() => {
    authentication.getIdToken().then((token) => {
      fetchBooks(token);
      setBookListChanged(false); //should be ok and won't result in recursion.
      window.scrollTo(0, 0);
    });
  }, [page, sortBy, filterBy, bookListChanged]);

  const filtersUsed = [
    { key: "all", value: "All Books" },
    { key: "published", value: "Posted" },
    { key: "readytopublish", value: "Ready To Post" },
    { key: "notreadytopublish", value: "Not Ready To Post" },
  ];

  const getNoBooksText = () => {
    if (booksloading) return "";

    let currentFilter = null;
    for (let index = 0; index < filtersUsed.length; index++) {
      const f = filtersUsed[index];
      if (f.key === filterBy) {
        currentFilter = f;
        break;
      }
    }
    if (!currentFilter || currentFilter.key === "all") {
      return "You have no books. Click the add a book to get started";
    } else {
      return "No books matching filter '" + currentFilter.value + "'";
    }
  };

  if (!books) return null;
  const editBookBox = (book) => {
    const width = isSmall ? "25ch" : "50ch";
    return (
      <Box sx={{ width: "100%", padding: "1rem", textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            borderRadius: "20px",
            width: width,
            whiteSpace: "nowrap",
          }}
          onClick={() => {
            history.push("bookcard/" + book.bookId);
          }}
        >
          <Typography noWrap>
            View/Edit "<b>{book.title}</b>"
          </Typography>
        </Button>
      </Box>
    );
  };
  const nobooks = books.length === 0;
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
          p: ".5rem 0 1rem 0",
        }}
      >
        <HelpPopover topic="book" />
        <Button
          variant="contained"
          color="action"
          size="large"
          sx={{ whiteSpace: "nowrap" }}
          startIcon={<AddIcon />}
          onClick={() => {
            setCurrentBook(null);
            history.push("/mybook/");
          }}
        >
          Add a book
        </Button>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <FormControl sx={{ width: "15ch", marginRight: "1rem" }}>
          <InputLabel id="sort-label">Sort</InputLabel>
          <Select
            size="small"
            labelId="sort-label"
            value={sortBy}
            label="Sort"
            onChange={(v) => {
              setSortBy(v.target.value);
              setPage(1);
            }}
          >
            <MenuItem value={"mostRecent"}>Newest</MenuItem>
            <MenuItem value={"leastRecent"}>Oldest</MenuItem>
            <MenuItem value="title">Title</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: "15ch" }}>
          <InputLabel id="filter-label">Filter</InputLabel>
          <Select
            size="small"
            labelId="filter-label"
            value={filterBy}
            label="Filter"
            onChange={(v) => {
              setFilterBy(v.target.value);
              setPage(1);
            }}
          >
            {filtersUsed.map((f, index) => {
              return (
                <MenuItem key={index} value={f.key}>
                  {f.value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      {nobooks && (
        <Box>
          <Typography
            variant="h6"
            sx={{
              width: "100%",
              color: "#333",
              textAlign: "center",
              padding: "3rem 0",
            }}
          >
            {getNoBooksText()}
          </Typography>
        </Box>
      )}
      {!nobooks && (
        <>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              p: ".5rem 0 0 0",
            }}
          >
            <IconButton
              color="action"
              disabled={Boolean(page < 2)}
              onClick={() => {
                setPage(page - 1);
              }}
            >
              <NavigateBefore />
            </IconButton>
            <Typography>
              {" "}
              Showing {(page - 1) * 10 + 1} - {Math.min(page * 10, numBooks)} of{" "}
              {numBooks} books (Page: {page})
            </Typography>
            <IconButton
              color="action"
              disabled={Boolean(page * 10 >= numBooks)}
              onClick={() => {
                setPage(page + 1);
              }}
            >
              <NavigateNext />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              p: "0",
              m: "0",
              //backgroundColor: 'secondary.main'
            }}
          >
            <Typography
              variant="h5"
              noWrap
              sx={{ p: "1rem", color: "secondary.contrastText" }}
            >
              {" "}
              My Books
            </Typography>
          </Box>
          {books.map((book, index) => {
            return (
              <Card
                sx={{
                  width: "100%",
                  m: ".5rem 0",
                  p: ".5rem 0 2rem 0",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  // backgroundColor: index % 2 == 0 ? '#d2e9d14a' : '#FFF'
                }}
                key={index}
                elevation={5}
              >
                {/* {editBookBox(book)} */}
                <Box
                  sx={{
                    p: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: { xs: "center", sm: "flex-start" },
                    alignContent: "flex-start",
                    width: "250px",
                    maxWidth: "250px",
                  }}
                >
                  <Typography
                    variant="formLabel"
                    component="h5"
                    noWrap
                    sx={{ fontWeight: "bold", width: "100%" }}
                  >
                    #{(page - 1) * 10 + index + 1}{" "}
                    {book.title ? book.title : "<No title>"}
                  </Typography>
                  <br />
                  <Button
                    onClick={() => {
                      history.push("bookcard/" + book.bookId);
                    }}
                  >
                    {getCover(book)}
                  </Button>
                </Box>
                <Box
                  sx={{
                    p: "1rem 0",
                    fontWeight: "bold",
                    width: "300px",
                    maxWidth: "300px",
                    // border:'1px solid red' ,
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    alignContent: "flex-start",
                  }}
                >
                  <Typography
                    variant="formLabel"
                    component="div"
                    sx={{ textAlign: "center", width: "100%" }}
                  >
                    Status:
                    <HelpPopover topic="bookPostingStatus" />
                  </Typography>
                  {getPostingStatus(book)}
                </Box>
                <Box
                  sx={{
                    p: "1rem",
                    fontWeight: "bold",
                    // flexGrow: 1,
                    justifyContent: "center",
                    width: "300px",
                    maxWidth: "300px",
                  }}
                >
                  <Typography variant="formLabel" sx={{ textAlign: "center" }}>
                    Discoverability: <HelpPopover topic="discoverability" />
                  </Typography>
                  {getPromoStatus(book, history)}
                </Box>
                {editBookBox(book)}
              </Card>
            );

            // return <BookCard index={index} book={book} key={index} page={page} />
          })}
        </>
      )}
    </>
  );
}

function getURL(partial) {
  return process.env.REACT_APP_WRITER_BOOK_URLBASE + partial;
}

function getPostingStatus(book) {
  if (book.publishStatus === "published") {
    return (
      <Typography component="div" sx={{ width: "100%", textAlign: "center" }}>
        Your book is live on MeetNewBooks.com at
        <Typography component="div" sx={{ width: "100%", textAlign: "center" }}>
          <a href={getURL(book.publishUrl)} target="_blank" rel="nofollow">
            {book.title} by {book.authorName}
            &nbsp;
            <div style={{ display: "inline-flex", verticalAlign: "middle" }}>
              <LaunchOutlinedIcon
                sx={{ display: "inline", fontSize: "19px" }}
              />
            </div>
          </a>
        </Typography>
      </Typography>
    );
  } else if (book.publishStatus === "readytopublish") {
    return (
      <Typography sx={{ width: "100%", textAlign: "center" }}>
        Ready to display on MeetNewBooks.com{" "}
      </Typography>
    );
  } else {
    return (
      <Typography sx={{ width: "100%", textAlign: "center" }}>
        Not Ready to display on MeetNewBooks.com{" "}
      </Typography>
    );
  }
}
function getPromoStatus(book, history) {
  if (!book.similarBooks || book.similarBooks.length === 0) {
    return (
      <Typography sx={{ width: "100%", textAlign: "center" }}>
        No similar books selected for promotion{" "}
      </Typography>
    );
  } else {
    return (
      <>
        <Typography sx={{ width: "100%", textAlign: "center" }}>
          {book.similarBooks.length}/3 book(s) selected for promotion
        </Typography>
        {book.similarBooks.map((b, i) => {
          return (
            <BookPromoStatus
              book={b}
              key={i}
              expiredMessage={
                <a
                  href="#"
                  onClick={() => {
                    history.push("bookcard/" + book.bookId);
                  }}
                >
                  Expired - Select another book.
                </a>
              }
            />
          );
        })}
      </>
    );
  }
}
function getCover(book) {
  const image = book.coverImage ? book.coverImage : "nocoveruploaded.png";

  return (
    <img
      alt=""
      src={"https://assets.meetnewbooks.com/" + image}
      style={{
        width: "130px",
        height: "190px",
        border: "2px solid #999",
        borderRadius: "10px",
        //    boxShadow: '8px 8px 8px 0 rgb(0 0 0 / 60%)'
      }}
    />
  );
}
