import { Button, Grid, Typography } from "@mui/material";
import * as React from "react";
import "react-quill/dist/quill.snow.css";
import WriterAvatar from "./avatar-component";
import WriterAboutMe from "./writer-aboutme-component";
import WriterSiteCard from "./writer-site-card-component";
import WriterProfileContext from "../../../contexts/writer-profile/writer-profile.context";
import CurrentBookContext from "../../../contexts/current-book/current-book.context";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router";
export default function WriterProfile(props) {
  const [writerProfile] = React.useContext(WriterProfileContext);
  const [, setCurrentBook] = React.useContext(CurrentBookContext);
  console.log("writerProfile", { writerProfile });
  const history = useHistory();

  const hasFullName = writerProfile && writerProfile.fullName;
  return (
    <>
      {hasFullName ? (
        <div style={{ width: "100%", textAlign: "right" }}>
          <Button
            variant="contained"
            color="action"
            size="large"
            sx={{ whiteSpace: "nowrap" }}
            startIcon={<AddIcon />}
            onClick={() => {
              setCurrentBook(null);
              history.push("/mybook/");
            }}
          >
            Add a book
          </Button>
        </div>
      ) : (
        <Typography
          sx={{
            margin: "1rem 0",
            p: "1.5rem 1rem",
            backgroundColor: "secondary.main",
          }}
          variant="h6"
        >
          Step 1: Create Your Author Profile
        </Typography>
      )}

      <Grid
        container
        sx={{ justifyContent: "space-evenly", columnGap: "1rem" }}
      >
        <Grid item>
          <WriterAvatar />
        </Grid>
        <Grid item sx={{ flexGrow: 1, p: "1rem 0 0 0", display: "flex" }}>
          <WriterSiteCard />
        </Grid>
      </Grid>
      <WriterAboutMe />
    </>
  );
}
