import { Button, Box, Dialog, DialogActions, DialogTitle, Slider, Typography, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone';
import { PersonAdd, PersonRemove } from '@mui/icons-material';
import { fetch as crossFetch } from 'cross-fetch';
import authentication from '../../../services/authentication';
import { DialogContent } from '@mui/material';
import { useTheme } from '@mui/material'
import { useMediaQuery } from "@mui/material";
import WriterProfileContext from '../../../contexts/writer-profile/writer-profile.context';

const urlBase = process.env.REACT_APP_URLBASE;

export default function WriterAvatarEditor(props) {
    const [writerProfile, setWriterProfile] = React.useContext(WriterProfileContext);

    const { setEditMode } = props;

    const defaultSelectedFile = writerProfile.profileImage ? urlBase + "profileImage?objectName=" + writerProfile.profileImage : null;
    const [selectedFile, setSelectedFile] = React.useState(defaultSelectedFile);

    const [busy, setBusy] = React.useState();
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState(0);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const editorRef = React.useRef(null);



    const onSave = (deletePic) => {
        setBusy(true);
        const canvasScaled = editorRef.current.getImageScaledToCanvas();
        const imageData = canvasScaled.toDataURL();
        authentication.getIdToken().then((token) => {
            const data = new FormData();
            data.append("file", imageData);
            data.append("idToken", token);
            data.append("delete", deletePic);

            crossFetch(urlBase + "saveProfileImage", {
                method: 'post',
                body: data
            }).then(res => {
                if (res.status >= 400) {
                    window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
                }
                return res.json();
            }).then((t) => {
                setBusy(false);
                var error = false;
                if (deletePic && !t.deleteImage) {
                    window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
                    error = true;
                }
                if (!deletePic && !t.objectName) {
                    window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
                    error = true;
                }
                if (!error) {

                    if (window.openSnackbar) {
                        window.openSnackbar("Your changes have been saved");
                    }
                    writerProfile.profileImage = deletePic ? null : t.objectName;
                    setEditMode(false);
                    setWriterProfile(writerProfile);
                }

            }
            ).catch(function (e) {
                window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
                setBusy(false);
            });
        });
    };




    // React.useEffect(()=>{
    //     setSelectedFile( writerProfile.imageUrl);
    // },[])


    return (
        <Dialog fullScreen={isSmall} maxWidth={isSmall ? 'xl' : 'md'} open={true} sx={{ p: 1 }}>
            <DialogTitle>Profile Picture</DialogTitle>
            <DialogContent>
                {busy && (<Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
                )}
                
                <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>

                {!selectedFile &&<Typography variant="caption" component="div" style={{width:'100%',textAlign:'center'}}>Drag a file to the box below, or use the upload button</Typography>}   
                            <Dropzone
                                onDrop={(e) => setSelectedFile(e[0])}
                                noClick
                                noKeyboard
                            >
                                {({ getRootProps, getInputProps }) => (<div {...getRootProps()}>
                                    <AvatarEditor width={300} height={300} image={selectedFile} crossOrigin="anonymous"
                                        border={2} borderRadius={0}
                                        color={[55, 55, 55, 0.6]} // RGBA
                                        scale={scale}
                                        rotate={rotation}
                                        ref={editorRef}
                                    />
                                   
                                </div>)}
                            </Dropzone>
                            <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'flex-start', p: 2 }}>

                                <Box sx={{ width: '100%', maxWidth: '250px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', p: 1 }}>
                                    <Typography>  Zoom:</Typography>

                                    <Slider
                                        aria-label="Zoom" defaultValue={1} step={0.01} onChange={(e) => {
                                            setScale(parseFloat(e.target.value));
                                        }}                                 //marks
                                        min={0.1} max={2} valueLabelDisplay="auto"
                                    />
                                </Box>
                                <Box sx={{ width: '100%', maxWidth: '250px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', p: 1 }}>
                                    <Typography>Rotate:</Typography>
                                    <Slider aria-label="Rotate"
                                        defaultValue={0} step={1} onChange={(e) => {
                                            setRotation(parseFloat(e.target.value))

                                        }}
                                        //marks
                                        min={-180} max={180} valueLabelDisplay="auto"
                                    />
                                </Box>
                            </Box>
                       
                    <Box sx={{ p: 1 }}>
                        <input
                            id="avatar-input" type="file"
                            hidden accept="image/*"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                        />

                        <label htmlFor="avatar-input">
                            <Button
                                // sx={!selectedFile ? { height: '200px' } : {}}
                                color="primary"
                                component="span"
                                startIcon={<PersonAdd />}

                                variant="outlined"
                            >
                                Click to Upload a new Picture
                            </Button>
                            
                        </label>
                    </Box>
                    {selectedFile && (<Box sx={{ p: 1 }}>
                        <Button
                            color="primary" component="span" startIcon={<PersonRemove />}
                            onClick={() => onSave(true)}
                            variant="outlined">Delete Profile Picture </Button>
                    </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setEditMode(false)}>Cancel</Button>
                <Button variant="contained" onClick={() => onSave(false)}>Save Changes</Button>

            </DialogActions>
        </Dialog>)
}
