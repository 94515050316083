import { Box, Typography, Button, Breadcrumbs } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom';
import CurrentBookContext from '../../../../contexts/current-book/current-book.context';
import bookService from '../book-service';
import PromoHistoryDetails from './promohistory-details.component';


export default function PromoHistory(props) {

    const { bookId } = props;
    const [book, setBook] = React.useContext(CurrentBookContext);
    const [promohistory, setPromoHistory] = React.useState(null);


    const history = useHistory();
    const onHistoryReceived = (map) => {
        if(promohistory==null) {

            setPromoHistory(map["books"]);
        }
    }

    const refreshData = () => {
        bookService.fetchPromoHistory(bookId, onHistoryReceived)
        window.scrollTo(0,0);
    };

    React.useEffect(() => {
        if(!book){
            bookService.fetchBook(bookId,setBook);
        }
        if(promohistory===null) {
            refreshData();
        }

    }, [bookId,promohistory]);

    const renderHist = (h,i) => {
        return (
            <Box key={i}>
                
                    <PromoHistoryDetails detail={h} index={i}/>
                

            </Box>
        )
    };

    if (!book) return null;

    if (!promohistory) return null;
    return (
        <Box sx={{ maxWidth: 'lg' }}>

            {/* <Button startIcon={<ArrowBack />} onClick={() => history.goBack()}>Back</Button> */}
            <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '.5rem' }}>

                <Button sx={{ textTransform: 'none' }} onClick={() => history.push("/mybooks")}> My Books </Button>
                <Button sx={{ textTransform: 'none' }} onClick={() => history.push("/bookcard/" + bookId)}> {book.title ? book.title : 'Untitled'} </Button>

                <Typography variant="formValue"> Promotion History for {book.title ? book.title : 'Untitled'} </Typography>

            </Breadcrumbs>
            {promohistory.map( (h,i)=>renderHist(h,i))}
        </Box>
    )


}
