import React, { useContext } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { Avatar } from "@mui/material";
import { useHistory } from "react-router-dom";
import CurrentTabContext from "../../contexts/current-tab/current-tab.context";
import WriterProfileContext from "../../contexts/writer-profile/writer-profile.context";

export default function HeaderMenu(props) {
  const [, setCurrentTab] = useContext(CurrentTabContext);
  const [writerProfile] = useContext(WriterProfileContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    onSettingsClick,
    onSignOutClick,
    onSignUpClick,
    onSignInClick,
    user,
  } = props;

  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasProfilePic = writerProfile && writerProfile.profileImage;

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ paddingRight: "0", color: "white" }}
      >
        {!user && <MenuIcon />}

        {user &&
          (hasProfilePic ? (
            <img
              style={{
                width: "48px",
                height: "48px",
                borderRadius: "50%",
              }}
              src={
                "https://assets.meetnewbooks.com/" + writerProfile.profileImage
              }
            />
          ) : (
            <Avatar
              sx={{
                backgroundColor: "#FFF",
                color: "#666",
              }}
            >
              {user.userData.nameInitials ? user.userData.nameInitials : ""}
            </Avatar>
          ))}
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={()=>history.push("/how-to")}>Help/FAQ</MenuItem> */}
        {/* {user && (
          <MenuItem onClick={()=>{
            history.push({
              pathname: '/guide_faq/',
              state: {
                goBack: true,
              }
            }) 
            
          }}>Guide & FAQ</MenuItem>


        )} */}
        {user && (
          <MenuItem
            onClick={() => {
              history.push("/myprofile");
              setCurrentTab("myprofile");
            }}
          >
            My Author's Profile
          </MenuItem>
        )}
        {user && writerProfile && writerProfile.fullName && (
          <MenuItem
            onClick={() => {
              history.push("/mybooks");
              setCurrentTab("mybooks");
            }}
          >
            My Books
          </MenuItem>
        )}
        {user && <MenuItem onClick={onSettingsClick}>Manage Account</MenuItem>}
        {user && (
          <MenuItem
            onClick={() => {
              history.push({
                pathname: "/contact/",
                state: {
                  goBack: true,
                },
              });
            }}
          >
            Get Help
          </MenuItem>
        )}
        {user && <MenuItem onClick={onSignOutClick}>Sign Out</MenuItem>}

        {!user && <MenuItem onClick={onSignInClick}>Sign In</MenuItem>}
        {!user && <MenuItem onClick={onSignUpClick}>Sign Up</MenuItem>}
      </Menu>
    </>
  );
}
