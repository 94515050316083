import { Typography, Box, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import CurrentBookContext from "../../../contexts/current-book/current-book.context";
import BookFormatEdit from "./book-format-edit.component";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function BookFormat(props) {
  const [book, setBook] = useContext(CurrentBookContext);
  const { editingFormat, setEditingFormat, editingDetail } = props;

  if (!book) return null;
  const format = book.format ? book.format : {};
  const published = Boolean(book && book.publishStatus === "published");
  return (
    <>
      {!editingFormat && (
        <Box
          sx={{
            m: "0",
            p: "0",
            borderBottom: "1px solid",
            borderColor: "primary.main",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              disabled={published || editingDetail}
              onClick={() => setEditingFormat(true)}
            >
              Edit Publishing
            </Button>{" "}
            &nbsp;
          </Box>

          <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ width: "100%", padding: ".5rem" }}>
              <Typography variant="formLabel">Book Format:</Typography>
              <Typography variant="formValue">
                {format.bookFormat ? format.bookFormat : "-"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", padding: ".5rem" }}>
              <Typography variant="formLabel">ASIN (Amazon):</Typography>
              {!format.asin && <Typography variant="formValue">-</Typography>}

              {format.asin && (
                <>
                  <a
                    href={"https://www.amazon.com/dp/" + format.asin}
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography>
                      {" "}
                      https://www.amazon.com/dp/{format.asin}
                    </Typography>
                  </a>
                  <Typography variant="caption">
                    Important: Click the link above to make sure this is correct
                  </Typography>
                </>
              )}
            </Box>
            <Box sx={{ padding: ".5rem" }}>
              <Typography variant="formLabel">ISBN 13:</Typography>
              <Typography variant="formValue">
                {format.isbn13 ? format.isbn13 : "-"}
              </Typography>
            </Box>
            <Box sx={{ padding: ".5rem" }}>
              <Typography variant="formLabel"># Pages:</Typography>
              <Typography variant="formValue">
                {format.pageCount ? format.pageCount : "-"}
              </Typography>
            </Box>

            <Box sx={{ width: "100%", padding: ".5rem" }}>
              <Typography variant="formLabel">Published Date:</Typography>
              <Typography component="span" variant="formValue">
                {format.publishedDay ? format.publishedDay : "--"}{" "}
                {format.publishedMonth ? format.publishedMonth : "---"}{" "}
                {format.publishedYear ? format.publishedYear : "----"}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {editingFormat && <BookFormatEdit setEditing={setEditingFormat} />}
    </>
  );
}
