import React from 'react';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { fetch as crossFetch } from 'cross-fetch';
import { Dialog, DialogContent, DialogActions ,Typography} from '@mui/material';
// import { analytics } from '../../firebase';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';

const urlBase = process.env.REACT_APP_URLBASE;

async function getStaticContent(contentType) {
  
    const response = await crossFetch(urlBase+'staticContent?contentType=' + contentType);
    return await response.json()
  }
export default function StaticContent(props) {

    const {contentType,open,handleClose}=props
    const [content,setContent]=useState();
    const theme = useTheme();
    const isSm= useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (contentType) {
            getStaticContent(contentType).then((t) => {
                setContent(t.content);
               
            });
        }
       
    }, [contentType,open]);
    
    
    if(!content) return null
    return (
        <Dialog fullScreen={isSm} maxWidth={isSm?'xl':'md'} open={open}
        onClose={handleClose}
        >
          
            <DialogContent>
                <Typography sx={{fontSize:'.8rem'}}>
                        <span dangerouslySetInnerHTML={{ __html: content }} />
                    </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>

    );
}
