import { Typography, Box } from '@mui/material';
import React, {  } from 'react';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
const statusStyles = {
    'confirmed': {
        height: '15px',
        width: '15px',
        backgroundColor: '#52be52',
        backgroundImage: 'linear-gradient(45deg,rgb(36 127 36),transparent)',

        borderRadius: '50%',
        display: 'inline-block'
    },
    'current': {
        height: '25px',
        width: '25px',
        // backgroundColor: '#52be52',
        backgroundImage: 'url(/flames.png)',
        // backgroundImage: 'linear-gradient(45deg,rgb(36 127 36),transparent)',
        borderRadius: '50%',
        display: 'inline-block'
    },
    'tentative': {
        height: '15px',
        width: '15px',
        backgroundColor: '#ffb300',
        backgroundImage: 'linear-gradient(45deg,rgb(163 147 9),transparent)',
        borderRadius: '50%',
        display: 'inline-block'
    },
    'expired': {
        height: '25px',
        width: '25px',
        // backgroundColor: '#52be52',
        backgroundImage: 'url(/expired.png)',
        // backgroundImage: 'linear-gradient(45deg,rgb(163 0 0),transparent)',
        borderRadius: '50%',
        display: 'inline-block'
    }
}
function getMeetNewBooksURL(partial) {

    return process.env.REACT_APP_WRITER_MEETNEWBOOKS_URLBASE +'book/'+ partial;

}
export default function BookPromoStatus({book, index, expiredMessage}) {

    

    const status = book.schedule.expired ? 'expired' :
        book.schedule.current ? 'current' :
            book.schedule.status === 'confirmed' ? 'confirmed' : 'tentative';

    const message = book.schedule.expired ? (expiredMessage || 'Expired - Select another book') :
        book.schedule.current ? 'Being recommended now!' :
            book.schedule.status === 'confirmed' ? 'On Track for Promotion with:' : 'Ready to promote once you submit';

    return <Box sx={{ paddingBottom: '1rem' }} key={index}>

        <Typography variant="notice" sx={{ textTransform: 'uppercase', color: '#666', fontSize: '.8rem', verticalAlign: 'top' }}>
            <span style={statusStyles[status]}></span>&nbsp;
            {message}</Typography>
        <br />
        <Typography variant="caption" component="div" noWrap sx={{ maxWidth: '200px' }}>
            <a target="_blank" rel="noreferrer nofollow" href={getMeetNewBooksURL(book.popularBook.idWithTitleSuffix)} >
                <Typography component="span" sx={{ color: 'primary.main', fontWeight: 'bold'}}>{book.popularBook.title}

                    &nbsp;<div style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                        <LaunchOutlinedIcon sx={{ display: 'inline', fontSize: '12px' }} />
                    </div></Typography>
            </a>
        </Typography>

        <Typography component="div"> Dates: </Typography>
        <Typography component="div" sx={{color:'#666',fontWeight:'bold'}}> {book.schedule.reservationStartDate} - {book.schedule.reservationEndDate}</Typography>

        <br />
    </Box>
}