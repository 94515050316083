import { fetch as crossFetch } from 'cross-fetch';
import authentication from '../../../services/authentication';


const bookService = {};

bookService.fetchBook=(bookId,callBack)=>{
    authentication.getIdToken().then((token) => {
            const url = process.env.REACT_APP_URLBASE + "getWriterBook?idToken=" + token + "&bookId=" + bookId;
            crossFetch(url)
              .then(res => {
                return res.json();
              })
              .then((data) => {
                callBack(data);
              }
              ).catch(function (e) {
                console.log("Got an error back" + e)
                window.gtag('event', 'exception', {
                  'description': e + ' url ' + url,
                  'fatal': false   // set to true if the error is fatal
        
                });
                window.openSnackbar("Sorry, something went wrong. Please refresh your browser and try again")
              })
      });
}
bookService.fetchSimilarBooks=(bookId,callBack)=>{
  authentication.getIdToken().then((token) => {
          const url = process.env.REACT_APP_URLBASE + "getSimilarBooks?idToken=" + token + "&bookId=" + bookId;
          crossFetch(url)
            .then(res => {
              return res.json();
            })
            .then((data) => {
              callBack(data);
            }
            ).catch(function (e) {
              console.log("Got an error back" + e)
              window.gtag('event', 'exception', {
                'description': e + ' url ' + url,
                'fatal': false   // set to true if the error is fatal
      
              });
              window.openSnackbar("Sorry, something went wrong. Please refresh your browser and try again")
            })
    });

}

bookService.fetchProfile = (token, setWriterProfile) => {
  const url = process.env.REACT_APP_URLBASE + "getProfile?idToken=" + token;
  fetch(url)
    .then(res => {
      return res.json();
    })
    .then((data) => {
      setWriterProfile(data);
    }
    ).catch(function (e) {
      console.log("Got an error back" + e)
      window.gtag('event', 'exception', {
        'description': e + ' url ' + url,
        'fatal': false   // set to true if the error is fatal

      });
    })
}

bookService.fetchSchedule= (token, popularBookId, bookId, setSchedule) =>{
  const data = new URLSearchParams();

  data.append("idToken", token);
  data.append("popularBookId", popularBookId);
  data.append("bookId", bookId);
  // data.append("delete", Boolean(deleteClicked));
  crossFetch(process.env.REACT_APP_URLBASE + "getNextSchedule", {
      method: 'post',
      body: data
  }).then(res => {
      if (res.status >= 400) {
          window.openSnackbar("Sorry, we're having trouble getting data. Please refresh your browser or try again later");
      }
      return res.json();
  }).then((t) => {

      var error = false;
      if (!t || !t.reservationStartDate) {
          window.openSnackbar("Sorry, we're having trouble getting data. Please refresh your browser or try again later");
          error = true;
      }
      if (!error) {
          setSchedule(t);
      }

  }
  ).catch(function (e) {
      window.openSnackbar("Sorry, we're having trouble getting data. Please refresh your browser or try again later");
      console.log("Got an error back" + e);
  });
 }

  bookService.fetchPromoHistory=(bookId,callBack)=>{
    authentication.getIdToken().then((token) => {
            const url = process.env.REACT_APP_URLBASE + "getPromoHistory?idToken=" + token + "&bookId=" + bookId;
            crossFetch(url)
              .then(res => {
                return res.json();
              })
              .then((data) => {
                callBack(data);
              }
              ).catch(function (e) {
                console.log("Got an error back" + e)
                window.gtag('event', 'exception', {
                  'description': e + ' url ' + url,
                  'fatal': false   // set to true if the error is fatal
        
                });
                window.openSnackbar("Sorry, something went wrong. Please refresh your browser and try again")
              })
      });
  
  };

  bookService.getPopularBook=(popularBookId,setPopularBook) =>{
    const url = process.env.REACT_APP_URLBASE + "books/book?type=book&id=" + popularBookId;
    crossFetch(url)
      .then(res => {
          return res.json();
      })
      .then(
          (data) => {
              if (data.id) {
                setPopularBook(data)
              }

          }
      ).catch(function (e) {
          console.log("Got an error back" + e)
          window.gtag('event', 'exception', {
              'description': e + ' url ' + url,
              'fatal': false   // set to true if the error is fatal

          });
      }).finally(() => {

    })
  };


export default bookService;
