import React from 'react';


import {
  AppBar,
  Toolbar,
  Container}
  from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';
import { useContext } from 'react';

import { useLocation } from 'react-router-dom'

import DialogHostWrapper from '../dialog-host/dialog-host-wrapper.component';
import CurrentUserContext from '../../contexts/current-user/current-user.context';
import TitleLogo from './title-logo-component';
const styles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)

  },
  toolbar: {
     //minHeight:'48px'
     //color:'red',
     [theme.breakpoints.down('lg')]: {
      minHeight:'48px'
      },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  
  search: {
    position: 'relative',
    marginLeft: 0,
    width: '100%',
    flexWrap: 'nowrap',
  },

  inputRoot: {
    color: 'inherit',
  },
  inputAutoComplete: {
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   width: '45ch',
    // },
    // [theme.breakpoints.up('lg')]: {
    //   width: '90ch',
    // },

  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
 
}));



const Header = (props) => {
  //  const user = useContext(CurrentUserContext);
  const classes = styles();
  //const [, setSearchBook] = useContext(SearchBookContext);
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);
  const theme = useTheme();

  const isTwoLine = useMediaQuery(theme.breakpoints.down('lg'));


  //const {user}=props;

  const pathname = useLocation().pathname;

  const isLandingPage = (pathname === '/' || pathname === '/popular-books') ? true : false;
  // const isLandingPage = true
  

  
  

  return (

    <div id="nav-wrap-div" className={classes.grow}>
      <AppBar color="primary" position="static">
        <Container disableGutters className={classes.root}>
          <Toolbar disableGutters className={classes.toolbar} >
            


          <Container maxWidth='lg' style={{ display: 'flex',justifyContent:'center', padding:'0 8px' }} disableGutters>
                <TitleLogo/>
              </Container>

            <div className={classes.grow} />
            <div>
              {/* <HeaderMenu user={user} userData={userData}/> */}
              <DialogHostWrapper user={currentUser} setCurrentUser={setCurrentUser} />
            </div>
          </Toolbar>
          
        </Container>

      </AppBar>
     
    </div>

  );
};

export default Header;
