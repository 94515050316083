import { IconButton, Box,Button } from '@mui/material';
import React, { } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

export default function BookValidationStatus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { messages,title } = props;
  const isGood = messages.length === 0;


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      {isGood && (<IconButton  size="small" sx={{ textTransform:'none',border:'1px solid #eee', marginLeft:'.25rem'}} onClick={handleClick}><CheckIcon sx={{color: 'green',}}/>
       
       </IconButton>)}
      {!isGood && (<IconButton sx={{ textTransform:'none' }} onClick={handleClick}>
          <WarningAmberIcon sx={{color: '#d5a61a'}}/>
      
      </IconButton>)}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          {!isGood && (
            <>
              
              {messages.map((m,i)=>{
                return <Typography sx={{fontSize:'.8rem',p:1}} key={i}>#{i+1}. {m}</Typography>
              }
                
              )}
              </>
            
            
          )}
          {isGood && (
            <Typography sx={{fontSize:'.8rem',p:1}} >This section is good to go!</Typography>
          )

          }
        </Box>


      </Popover>
    </>
  );

}