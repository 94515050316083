import * as React from "react";
import {
  Typography,
  Box,
  Button,
  CircularProgress,
  Breadcrumbs,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import BookCover from "./cover/book-cover.component";
import CurrentBookContext from "../../../contexts/current-book/current-book.context";
import { useHistory } from "react-router";
import BookDetailEdit from "./book-detail-edit.component";
import BookFormat from "./book-format.component";
import { ArrowBack, WarningAmber } from "@mui/icons-material";
import BookDelete from "./book-delete";
import bookService from "./book-service";

export default function BookDetail(props) {
  const [book, setBook] = React.useContext(CurrentBookContext);

  const bookId = (book && book.bookId) || props.bookId; //use context first, then check passed param
  const [editing, setEditing] = React.useState(false);
  const [editingFormat, setEditingFormat] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    if (bookId) {
      bookService.fetchBook(bookId, setBook);
      if (!editing && !editingFormat) {
        window.scrollTo(0, 0);
      }
    } else if (!book) {
      //right after a book is added, we don't have a bookId param
      setBook({
        bookId: null,
        format: {},
      });
      setEditing(true);
    }
  }, [bookId, editing, editingFormat]);
  console.log("book-detail" + bookId);
  if (!book)
    return (
      <Box>
        <CircularProgress />
      </Box>
    );

  const published = Boolean(book && book.publishStatus === "published");
  return (
    <Box sx={{ maxWidth: "lg" }}>
      {/* <Button startIcon={<ArrowBack />} onClick={()=>history.push("/mybooks")}>Back to My Books</Button> */}
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: ".5rem" }}>
        <Button
          sx={{ textTransform: "none" }}
          onClick={() => history.push("/mybooks")}
        >
          {" "}
          My Books{" "}
        </Button>
        {bookId && (
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => history.push("/bookcard/" + bookId)}
          >
            {" "}
            {book.title ? book.title : "Untitled"}{" "}
          </Button>
        )}
        <Typography variant="formValue"> View/Edit </Typography>
      </Breadcrumbs>
      {bookId && doneWithDetailsButton(history, bookId)}
      {bookId &&
        book.detailsComplete &&
        book.detailsComplete.map((m, i) => {
          return (
            <Typography
              component="div"
              sx={{ fontSize: ".9rem", paddingTop: "1rem" }}
              key={i}
            >
              <div style={{ display: "inline-flex", verticalAlign: "middle" }}>
                <WarningAmber sx={{ color: "#af8625", fontSize: "20px" }} />
                &nbsp;
              </div>
              {m}
            </Typography>
          );
        })}

      {published && (
        <Typography variant="notice" sx={{ display: "block" }}>
          This book has been posted to meetnewbooks.com. <br />
          To make changes, you must first un-post it from meetnewbooks{" "}
        </Typography>
      )}
      <Typography
        sx={{
          margin: "1rem 0",
          p: "1rem 1rem",
          backgroundColor: "secondary.main",
        }}
        variant="h6"
      >
        {book.title ? book.title : "Enter Book Details And Upload Cover"}
      </Typography>

      <BookCover book={book} />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <BookDelete book={book} />
      </Box>

      <Typography
        sx={{
          margin: "1rem 0",
          p: "1rem 1rem",
          backgroundColor: "secondary.main",
        }}
        variant="h6"
      >
        Book Details
      </Typography>

      {!editing && (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              onClick={() => setEditing(true)}
              disabled={published || editingFormat}
            >
              Edit Book Details
            </Button>
          </Box>
          <Box justifyContent="center">
            <Typography variant="formLabel">Book Title:</Typography>
            <Typography variant="formValue">
              {book.title ? book.title : "-"}
            </Typography>

            <Typography variant="formLabel">Series:</Typography>
            <Typography variant="formValue">
              {book.series ? book.series : "-"}
            </Typography>

            <Typography variant="formLabel"># in Series:</Typography>
            <Typography variant="formValue">
              {book.bookSequence ? book.bookSequence : "-"}
            </Typography>

            <Typography variant="formLabel">Author Name(s):</Typography>
            <Typography variant="formValue">
              {book.authorName ? book.authorName : "-"}
            </Typography>

            <Typography variant="formLabel">Tags(s):</Typography>
            <Typography variant="formValue" component="p">
              {book.tags ? book.tags.split("\n").join(", ") : "-"}
            </Typography>

            <Typography variant="formLabel">Synopsis:</Typography>
            <Typography className="editorHtml">
              <span
                dangerouslySetInnerHTML={{
                  __html: book.synopsis ? book.synopsis : "<p>------</p>",
                }}
              />{" "}
            </Typography>
          </Box>
        </>
      )}
      {editing && <BookDetailEdit setEditing={setEditing} />}
      <Typography
        sx={{ margin: "1rem 0", p: "1rem", backgroundColor: "secondary.main" }}
        variant="h6"
      >
        Publishing Details
      </Typography>
      <BookFormat
        editingFormat={editingFormat}
        setEditingFormat={setEditingFormat}
        editingDetail={editing}
      />
    </Box>
  );
}

function doneWithDetailsButton(history, bookId) {
  return (
    <Box
      sx={{
        marginTop: "1rem",
        maxWidth: "lg",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        sx={{ textTransform: "none" }}
        onClick={() => history.push("/bookcard/" + bookId)}
        startIcon={<ArrowBack />}
      >
        {" "}
        Done with Book Details{" "}
      </Button>
    </Box>
  );
}
