import { Box, Typography, CircularProgress ,Button} from '@mui/material'
import React from 'react'
import PopularBookCard from './popular-book-card.component';
import { useHistory } from 'react-router-dom';
import CurrentBookContext from '../../../../contexts/current-book/current-book.context';
import BooksLoadingContext from '../../../../contexts/books-loading/books-loading.context';
import bookService from '../book-service';
import { useLocation } from 'react-router';
import HelpPopover from '../../../help/help-popover';
import BookListChangedContext from '../../../../contexts/book-list/book-list-changed.context';


export default function PromoteBook(props) {
    const MAX_ALLOWED_BOOKS = 3;
    const location = useLocation();
    
    const {bookId}=props;
    const [book,setBook]=React.useContext(CurrentBookContext);
    const [changed,] = React.useContext(BookListChangedContext);

    const [allSimilarBooks,setAllSimilarBooks]=React.useState(null);

    const [busy, setBusy] = React.useContext(BooksLoadingContext);
    const history = useHistory();     

    
    function fillAllSimilarBooks(arr) {

        arr = arr || [];
        const copy = []
        //put the books in the right place
        for (var i = 0; i < MAX_ALLOWED_BOOKS; i++) {
            if(arr[i]){
                copy[arr[i].similaritySequence -1]=arr[i];
            }
           
        }
        //fill in any missing.. if they deleted #2 in the middle 
        for ( i = 0; i < MAX_ALLOWED_BOOKS; i++) {
            if(!copy[i]){
                copy[i]={
                    popularBookId:null,
                    similaritySequence :i+1
                }
            }
        }
        return copy;
    }
    const onSimilarBooksReceived = (data) => {
        setBook(data);
        
        const allSimilarBooksTmp = [...data.similarBooks];
        setAllSimilarBooks(fillAllSimilarBooks(allSimilarBooksTmp))
        
        setBusy(false);
        const hash= location.hash
        if(hash){
            const node = document.querySelector('#hash_'+hash.replace('#',''));
            if(node){
                node.scrollIntoView({behaviour:'smooth'})
            }
        }
        else {
            window.scrollTo(0,0)    
        }
        
    }
    const refreshData=()=>{
        bookService.fetchSimilarBooks(bookId,onSimilarBooksReceived)
    };

    React.useEffect(() => {
        refreshData();

    }, [bookId, changed]);


    if (!book) return null;

    if(!allSimilarBooks) return null;    

    
    return (
        <> 
            <Box sx={{minHeight:'1vh'}}>
                {busy && (<Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
                )}
                <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', p: "1rem 0 0 0" }}>
                    <Typography noWrap variant="notice" sx={{ width:'100%', p:'1rem 0 0 0', textAlign:'center',fontSize:'1.2rem'}}>
                        <b>Promote Your Book</b> <HelpPopover topic="discoverability"/> <br/>
                        
                    </Typography>
                    <Typography noWrap  sx={{ width:'100%', p:'0', textAlign:'center'}}>
                        
                        Help readers discover {book.title?book.title:'your book'}
                    </Typography>
                    <Typography sx={{ width:'100%', p:'1rem 0 0 0', textAlign:'center'}}>
                        Add upto {MAX_ALLOWED_BOOKS} similar, well-known , 'popular' books that your target readers would be familiar with.
                    </Typography>

                    <Typography sx={{ width:'100%',textAlign:'center'}}>
                    We'll display {book.title} in the 'new and exciting' section when readers view any of the books below
                    </Typography>
                </Box>
                <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', p: "1rem 0 0 0" }}>


                   
                        {allSimilarBooks && allSimilarBooks.map((asb, index) => {
                            return (
                                <Box sx={{ p: '1rem 0',width:'100%' }} key={index} id={'hash_'+(index+1)}>
                                    <Box sx={{display:'flex', justifyContent:'center'}}>
                                    <PopularBookCard book={book} similarBook={asb} onChange={refreshData} />
                                    </Box>
                                </Box>
                            )
                        })}
                   

                </Box>
                <Box sx={{display:'flex', justifyContent:'flex-end'}}>
                    <Button  onClick={()=>history.push("/promohistory/"+bookId)}>History</Button>
                </Box>
            </Box>
            
        </>)


}
