import React from "react";
import {
  Typography,
  Box,
  IconButton, Button,
  Popover
} from "@mui/material";
import { Close, Help } from "@mui/icons-material";
import LabelImportantIcon from '@mui/icons-material/LabelImportant';


const linesStyle = {
  color: '#333',
  width: '100%',
  p: 0, m: '.5rem 0',
  display: 'flex', alignContent: 'flex-start', alignItems: 'flex-start'
}
const labelIconStyle = {
  paddingRight: '.5rem',
  opacity: '.9'
}
export default function HelpPopover({ topic, alternateText }) {
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const topics = {
    "bookPostingStatus": <Box>
      <>
        {bulletedLine(<>What does the Status mean?
          {bulletedLine("Live :  Your book is being displayed on MeetNewBooks.com")}
          {bulletedLine("Ready :  We have all the details we need to display your book. Click View/Edit to review the book details, then submit.")}
          {bulletedLine("Not Ready : We need additional information on this book before we can display it.")}
        </>)}



      </>
    </Box>,

    "discoverability": <Box>


      {bulletedLine("You can select upto 3 'popular' books to promote your book.Please make sure you choose relevant, similar books")}

      {bulletedLine("For each of the 3 'popular' books, enter a review, tags and rating, and a blurb on why your book is similar/why the reader could like your book")}

      {bulletedLine("Once you submit, readers will be recommended your book alongside your review")}
      {bulletedLine(<> We display only one book in the "New and Exciting"  book at a time, so the promotion slot is exclusively yours for that week!</>)}

      {bulletedLine(<> Each book promotion runs for one week. This allows other authors a fair chance to promote their work using the same books as you did.
        <br />We display the dates your book will be promoted on for each of your 'popular' book selections.</>)}

      {bulletedLine(<> After the week is over, you can select another book to promote with.
        Depending on your genre, you can promote your book for a long time alongside a large number of well known popular books.</>)}


    </Box>,
    "authorprofile": <Box>
      {bulletedLine(<>Your Author Profile page is a public page on meetnewbooks.com exclusively for you!</>)}
      {bulletedLine(<>Other than your name, all fields are optional, but highly recommended. </>)}
      {bulletedLine(<>Links to all of your submitted books will be displayed here as well. </>)}
    </Box>,
    "book": <Box>
      {bulletedLine(<>You can add as many books as you like. Similar to your 'Author Profile', your books will be hosted on meetnewbooks.com.</>)}
      {bulletedLine(<>We allow most mainstream genres, with the exception of erotica, or any content that may be construed as offensive. We reserve the right to review and remove any such content</>)}
      {bulletedLine(<>Once you've submitted your book, it will be available on meetnewbooks.com for as long as you like. </>)}
      {bulletedLine(<>You can also select upto 3 mainstream, popular and similar books to promote your book</>)}

    </Box>,
    "asin": <Box>
      {bulletedLine(<>We need the  ASIN for your book, so that we can direct readers to your Amazon book product page. You can find the ASIN on the AMAZON link to your book:
        <br /><span style={{ wordBreak: 'break-word' }}>https://www.amazon.com/..../dp/<b>YOUR ASIN</b>/etc=etc..., (right after  '/dp/')</span>
        <br /> or <span style={{ wordBreak: 'break-word' }}>https://www.amazon.com/gp/product/<b>YOUR ASIN</b>/etc..</span>
        <br /> Once you save, be sure to test the link to make sure we have the right book!
      </>)}
    </Box>,
    "promo-landing": <Box>
       
      {bulletedLine(<>Choose upto 3 popular, similar books. For example, if you wrote a children's fantasy, you might chose Harry Potter, Percy Jackson and Howl's Moving Castle as similar books.</>)}
      {bulletedLine(<>For each book, add your review. Your review is displayed immediately on meetnewbooks.com, with a link to your author page.</>)}
      {bulletedLine(<>Add why you think your book is similar ( i.e. why someone who liked this mainstream, popular book could like your book). This is your pitch to the reader.</>)}
      {bulletedLine(<>The system will find the next available promo slot for your book. For one week, your book will be displayed right below your review, alongside your 'pitch'</>)}
      {bulletedLine(<>The system will also push your review to the first spot for that week.</>)}
      {bulletedLine(<> The promo lasts one week for each of the three books you select. After that, your reviews still remain, so readers can still find you and your book.</>)}
      {bulletedLine(<> You can select 3 new mainstream books to promote again, and repeat this process for as long as you like.</>)}


    </Box >
  }

  const content = topics[topic];
  if (!content) return null;
  return (
    <>

      {/* <IconButton aria-describedby={id} variant="contained" onClick={handleClick} size="small" color="help">
          <Help />
        </IconButton> */}

      <Button aria-describedby={id} onClick={handleClick} size="small" color="help"
        sx={{
          textTransform: 'none', paddingLeft: '0', paddingRight: '0',
          "& .MuiButton-startIcon": { marginRight: '0px' }

        }} startIcon={<Help />}>
        {alternateText ? alternateText : 'Help'}
      </Button>


      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >   <Box sx={{ position: 'relative', backgroundColor: '#ddd8ab54', maxWidth: 'md', }}>
          <IconButton style={{ position: 'absolute', right: 0, top: 0 }} onClick={handleClose}>
            <Close />
          </IconButton>
          <Box sx={{ padding: '2rem 2rem 2rem 2rem' }}>{content}</Box>
        </Box>
      </Popover>
    </>
  );
}
function bulletedLine(text) {
  return <Typography component="div" sx={linesStyle}>

    <LabelImportantIcon style={labelIconStyle} color='primary' />
    <Typography sx={{ fontSize: '.9rem' }}>
      {text}
    </Typography>
  </Typography>;
}