
import * as React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Box,Typography} from '@mui/material'

export default function HtmlEditor (props){
    const {setHtml, setTotalChars, defaultValue, maxChars}=props;
    const [chars,setChars]=React.useState();
    const editorRef = React.useRef();
    
    const checkAndUpdateChars=(html,arg1,arg2, api)=>{
      
      const l = api.getLength();
      setChars(l);
      setTotalChars(l);
      
      setHtml(html);

    }
    React.useEffect(()=>{
        if(editorRef){
          const editor = editorRef.current.getEditor();
          const l = editor.getLength();
          setChars(l);
          setTotalChars(l);
        }
        
    },[]);
   //console.log("Html Editor")
    const modules = {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"],
            // [
            //   { list: "ordered" },
            //   { list: "bullet" },
            //   { indent: "-1" },
            //   { indent: "+1" },
            //   { align: [] }
            // ],
           
            ["clean"]
          ],
          
        },
        
      };
    
      const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        // "blockquote",
        // "size",
        // "color",
        // "list",
        // "bullet",
        // "indent",
        // "align"
      ];
    
return (
 <Box>
   
 <ReactQuill theme="snow" ref={editorRef}
      onChange={checkAndUpdateChars} placeholder="Start Typing..."
      defaultValue={defaultValue} formats={formats} modules={modules}
      
      />
      {chars && (<Typography style={{ color:(chars>maxChars)?'red':'#333',fontSize:'.8rem',width:'100%',textAlign:'right',visibility:(chars>1?'visible':'hidden')}} >{chars} /{maxChars}</Typography>)}
      {!chars && (<Typography style={{ color:(chars>maxChars)?'red':'#333',fontSize:'.8rem',width:'100%',textAlign:'right',margin:0,padding:0}}>{maxChars} characters</Typography>)}
   
      </Box>
);
}
