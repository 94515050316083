import React,{useContext} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import {Box,Button, useMediaQuery} from '@mui/material';
import WriterProfile from '../writer-book/profile/writer-profile-component';
import WriterBookList from '../writer-book/mybooks/book-list.component';
import CurrentTabContext from '../../contexts/current-tab/current-tab.context';
import CurrentUserContext from '../../contexts/current-user/current-user.context';
import FirebaseInitedContext from '../../contexts/firebase/firebase-inited.context';
import { CircularProgress } from '@mui/material';
import { useHistory ,useParams} from 'react-router';
import BookDetail from '../writer-book/book/book-detail.component';
import BookSample from '../writer-book/book/sample/book-sample.component';
import PromoteBook from '../writer-book/book/promote/book-promote.component';
import { useTheme } from '@emotion/react';
import WriterProfileContext from '../../contexts/writer-profile/writer-profile.context';
import authentication from '../../services/authentication';
import BookCard from '../writer-book/mybooks/book-card.component';
import AcceptTerms from './accept-terms';
import bookService from '../writer-book/book/book-service';
import VerifyEmail from './verify-email';
import PromoHistory from '../writer-book/book/promote/promohistory.component';

function TabPanel(props) {
  const { children, value ,index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p:'.5rem .5rem' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,

};

export default function Dashboard(props) {

  const [currentTab,setCurrentTab]= useContext(CurrentTabContext)
  const [writerProfile, setWriterProfile]= useContext(WriterProfileContext);

  const [user,] = useContext(CurrentUserContext)
  const [firebaseInited]=useContext(FirebaseInitedContext)
  const history = useHistory();
  const {bookId} = useParams();
  const {action} = props;
  const theme= useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  
  
  React.useEffect(()=>{
    if(!currentTab){
      setCurrentTab("myprofile");
    }
    if(firebaseInited && user && !writerProfile){
      authentication.getIdToken().then((token) => {
        bookService.fetchProfile(token,setWriterProfile)
      });
    }
  
},[firebaseInited,user])
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
   
    history.push("/"+newValue)
  };

  if(!firebaseInited){
     return <Box><CircularProgress/></Box>
  }

  if(!user){
    return <Box sx={{paddingTop:'4rem',display:'flex',flexWrap:'wrap', justifyContent:'center', width:'100%'}}>
    <Typography variant="h6">You are not signed in.</Typography>

    <Box sx={{p:4,display:'flex', width:'100%',justifyContent:'center',}}>
    <Button color="action" onClick={()=>window.signInDialog()} variant="contained">Sign In</Button>
    &nbsp;
    <Button color="action" variant="contained"onClick={()=>window.signUpDialog()}>Sign UP</Button>
    </Box>
    <Box sx={{p:4,display:'flex', width:'100%',justifyContent:'center'}}>
      <Button onClick={()=>history.push("/")}>Home</Button>
    </Box>
    </Box>
  }
  if(user && !writerProfile){
    return <Box><CircularProgress/></Box>
 }
 if(user && !writerProfile.emailVerified){
  return <VerifyEmail user={user} />
} 
 if(user && !writerProfile.acceptedTerms){
    return <AcceptTerms user={user} />
 }
  return (
    <Box sx={{ width: '100%',padding:'0' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="Writer Tabs" 
        indicatorColor="secondary" centered={isSmall?true:false}
        >
          <Tab label="My Profile" value="myprofile"  />
          <Tab disabled={!writerProfile || !writerProfile.fullName} label="My Books" value="mybooks" />
          {/* <Tab label="Add Book"  /> */}
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index="myprofile">
       <WriterProfile/>
      </TabPanel>
      <TabPanel value={currentTab} index="mybooks">  

       {myBooksActions()}
      </TabPanel>
      
    </Box>
  );

  function myBooksActions() {

    if(action === "mybook-sample") return (<BookSample bookId={bookId} />)
    else if(action === "mybook-promote") return (<PromoteBook bookId={bookId} />)
    else if(action === "bookcard") return (<BookCard bookId={bookId} />)
    else if(action === "promohistory") return (<PromoHistory bookId={bookId} />)
    else if(action === "mybook") return (<BookDetail bookId={bookId} />)
    else if(action === "mybooks") return (<WriterBookList/>)
  }
}
