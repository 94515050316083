import { SvgIcon,Typography,Link,Box }
    from "@mui/material";
import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
const titleLogoStyle = {
    // display: 'block',
    // verticalAlign: 'top',
    paddingTop: '7px',
    fontSize:'25px'
    // paddingRight: theme.spacing(1),
    // [theme.breakpoints.up('sm')]: {
    //     paddingRight: theme.spacing(2)
    // },
};
const titleText= {
    display: 'block',
    verticalAlign: 'top',
    paddingLeft: '5px',
    paddingTop: '0px',
    fontSize:'25px',
    textAlign:'center',
    color:'primary.contrastText'
   // fontSize:'1.5rem'
    // [theme.breakpoints.up('sm')]: {
    //   display: 'block',
    //   verticalAlign: 'top',
    //   paddingLeft: '5px',
    //   paddingTop: '0px'
    // },
  };
//   const logoLink= {
//     display: 'inline-block',
//     marginTop: '-10px'
//   };
export default function TitleLogo(props) {
    return <Box sx={{
        display:'flex',flexWrap:'nowrap',justifyContent:'flex-start',
        minWidth:'200px'}}>
        <div style={titleLogoStyle}>
            <Link color="inherit" component={RouterLink} to="/" underline="none"
                >
                {<SvgIcon
                    viewBox="0 0 180 180" style={{ fontSize: 'inherit' }}
                >
                    <path d="M31.1 8c-1.7 2.8-3.1 5.4-3.1 5.8 0 .4 2.6 1.7 5.8 2.9 21.4 8.4 41 25.6 52.2 46 1.3 2.4 2.5 4.1 2.7 3.9.2-.2-.1-2.5-.8-5.2C85.6 52 75.6 37 62.8 23.9 53.5 14.3 39.2 3 36.4 3c-1.6 0-3.1 1.5-5.3 5zM133 9.7c-19.2 15-37.1 37.4-40.6 50.8-1.6 6-1.6 5.5-.5 5.5.5 0 1.4-1.4 2.2-3.2 1.5-3.8 11-16.6 16.5-22.4 6.6-7 18.3-15.3 28.8-20.5 5.6-2.7 10.7-4.9 11.3-4.9 2 0 1.5-2-1.8-7.2-2.2-3.3-3.8-4.8-5.3-4.8-1.2 0-6 3-10.6 6.7zM15.7 27.9l-3.6 6.9 6.2.6c9 1 22.3 4.5 29.3 7.6 13.4 6 30 19.9 35.6 29.7 1.6 2.9 3.4 5.6 4 5.9 2.3 1.4 1.8-1-1.7-8.3-3-6.1-5.6-9.6-12.4-16.3-4.7-4.7-12-10.8-16.1-13.6-7-4.8-30.9-17.4-35.6-18.8-1.8-.5-2.6.4-5.7 6.3z" /><path d="M158 21.7c-5.1 1.8-26.9 13.4-34 18.1-12.8 8.4-24.8 20.9-29.5 30.5-2 4.3-3.5 8.1-3.2 8.4 1 .9 2.7-.8 4.7-4.7 4.3-8.5 18.4-21.2 31.4-28.5 7.3-4 21-8.2 32.3-9.7l8.2-1.1-3.6-6.9c-1.9-3.7-3.8-6.8-4.1-6.7-.4 0-1.4.3-2.2.6z" /><path d="M2 47.7c0 .4 2.7 6.2 6 12.8 12.6 25.5 16.5 41.2 20 81 .7 7.1 1.4 13.3 1.7 13.7.2.5 3.1.8 6.3.8 15.6 0 35.2 7.6 46.7 18.1 5.7 5.2 8.9 5.2 14.6 0 11.2-10.3 31.3-18.1 46.7-18.1 3.2 0 6.1-.4 6.4-.8.2-.4.9-6.4 1.5-13.2 3.5-39.5 7.7-56.5 20.1-81.5 3.3-6.6 6-12.4 6-12.8 0-1-8.2-.9-17.8.3-19.4 2.5-47.5 15.9-63.1 30.1-3.5 3.3-6.7 5.9-7.1 5.9-.4 0-3.6-2.6-7.1-5.8-8.9-8.1-15.6-12.6-28.4-18.7C41 53 29.8 49.3 20 48c-9-1.1-18-1.3-18-.3zm39 16.4c13.2 2.1 27.9 8.3 38.8 16.3L86 85v86.1l-7.8-7c-9-8.2-19.1-15.1-27.9-19.1-3.5-1.6-6.3-3.2-6.3-3.7-.5-15.7-4.1-38.2-8.6-53-2-6.7-7.8-20.8-10.1-24.6-.6-1 8.1-.9 15.7.4zm110.4 6.6c-6.8 14.6-12.7 37.5-14 53.7-.2 3.9-.7 9.5-1 12.4l-.5 5.2-7.8 3.8c-9.3 4.6-18.4 11-27.1 19.1-3.6 3.4-6.6 6.1-6.7 6.1-.2 0-.3-19.4-.3-43V85l6.3-4.6c14.1-10.4 32.6-17 48.9-17.3l5.7-.1-3.5 7.7z" />
                </SvgIcon>}
            </Link>
        </div>
        <Typography sx={titleText} variant="h6">
               Writers@Meet New Books<span style={{fontSize:'.6rem'}}>Beta</span>
        </Typography>
    </Box>
}