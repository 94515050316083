import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import './App.css';

import HomePage from './pages/homepage/homepage.component';

import Header from './components/header/header.component';
import CurrentUserContext from './contexts/current-user/current-user.context';

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
//import OtherComponent from './OtherComponent';
//const OtherComponent = React.lazy(() => import('./OtherComponent'));

import { Container } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import WriterProfileContext from './contexts/writer-profile/writer-profile.context';
import StaticContentDirect from './components/static-content/static-contact-direct-component';
import BooksLoadingContext from './contexts/books-loading/books-loading.context';
import Backdrop from '@mui/material/Backdrop';
import CurrentTabContext from './contexts/current-tab/current-tab.context';
import CurrentBookContext from './contexts/current-book/current-book.context';
import FirebaseInitedContext from './contexts/firebase/firebase-inited.context';
import BookListChangedContext from './contexts/book-list/book-list-changed.context'
const Footer = React.lazy(() => import('./components/footer/footer.component'));
const AuthHandler = React.lazy(() => import('./components/auth/auth-handler-component'));

const ContactDirect = React.lazy(() => import('./components/contact/contact-direct-component'));

const FirebaseInit = React.lazy(() => import('./components/firebase/firebase-init.component'));

const theme = responsiveFontSizes(createTheme({
  //palette #8db58f
  palette: {
    //  primary: 'red',

    primary: {
      // main: '#455964',
      // main: '#4b406c',
      // main:'#126b97',
      // main:'#1e3f50',
      main:'#607e62',

      contrastText: '#fff',
    },
    secondary: {
     
      // main: '#b9c0ba',//'#8fae9063',
      main:'#ebe4cf',
      contrastText: '#333',
    },
    tertiary:{
      main:'#ebe4cf',
      contrastText: '#333',
    },
    accent: {
      //  main: '#b58272',
      main: '#AAA',
      contrastText: '#fff',
    },
    accent2: {
      main: '#e3dfd369',
      contrastText: '#000',
    },
    linkColor: {
      //main:'#184e72e6'//see index.css . <a> tag uses that
      main: '#1f64a0'//see index.css . <a> tag uses that
    },
    action: {
      main: '#bd4a38',
      // main:'#ff8a65',
      contrastText: '#FFF',
    },
    simple: {
      main: '#333',
      // main:'#ff8a65',
      contrastText: '#FFF',
    },
    textAccent: {
      // main: '#bd4a38',
      main:'#455964',
      contrastText: '#333',
    },
    tags: {
      main:'#255607',
      contrastText: '#FFF',
    },
    help: {
      contrastText: '#FFF',
      main: '#666',
    }
  },

  typography: {

    fontFamily: [
      "'Roboto Slab', serif",
    ].join(','),
    bookTitle: {
      fontFamily: "'Roboto Slab', serif",
    },
    formLabel: {
      // fontFamily: "'Fira Mono', cursive",
      fontFamily: "'Roboto Slab', serif",
      fontSize:'1.1rem',
      fontWeight:'bold',
      color:'#455964',
      padding:'.25rem',
      display:'block',
    },
    formValue: {
      fontFamily: "'Roboto Slab', serif",
      fontSize:'.9rem',
      color:'#333', //check index.css for htmlEditor style if you change this
      display:'block',
      padding:'.25rem'
    },
    body1: {
      fontWeight: 500,
    },
    h6: {
      color:'#333'
    }, h5: {
      color:'#333'
    }, h4: {
      color:'#333'
    },
    button :{
      // fontFamily: "'Fira Mono', cursive",
      fontFamily: "'Roboto Slab', serif",
    },
    notice:{
      fontFamily: "'Permanent Marker', cursive",
      // fontFamily: "'Boogaloo', cursive",
      color: '#607e62'
    },
    fancy:{
      fontFamily: "'Permanent Marker', cursive",
      // fontFamily: "'Boogaloo', cursive",
      color: '#333'//'#8707fd'
    }
    //fontSize:'.9rem'
  },


}));
class App extends React.Component {
  constructor() {
    super();

    this.setCurrentUser = (searchItem) => {
      this.setState(state => ({
        currentUser: searchItem,
      }));
    };
    this.setWriterProfile = (searchItem) => {
      this.setState(state => ({
        writerProfile: searchItem,
      }));
    };
    this.setBooksLoading = (searchItem) => {
      this.setState(state => ({
        booksLoading: searchItem
      }));
    };
    this.setCurrentTab = (t) => {
      this.setState(state => ({ currentTab: t }))
    };
    this.setCurrentBook = (b) => {
      this.setState(state => ({ currentBook: b }))
    };
    this.setFirebaseInited= (b) => {
      this.setState(state => ({ firebaseInited: b }))
    };
    this.setBookListChanged= (b) => {
      this.setState(state => ({ bookListChanged: b }))
    };
    this.state = {
      currentUser: null,
      writerProfile: null,
      setCurrentUser: this.setCurrentUser,
      setWriterProfile: this.setWriterProfile,
      staticContentType: null,
      booksLoading: false,
      setBooksLoading: this.setBooksLoading,
      currentTab: 'myprofile',
      setCurrentTab: this.setCurrentTab,
      currentBook: null,
      setCurrentBook: this.setCurrentBook,
      firebaseInited:false,
      setFirebaseInited:this.setFirebaseInited,
      bookListChanged:false,
      setBookListChanged:this.setBookListChanged
    }

  }


  render() {
    const { booksLoading, } = this.state;
    return (

      <MuiThemeProvider theme={theme}>
        <Container maxWidth={false} id="app" disableGutters sx={{ paddingBottom: '3rem', position: 'relative', width: '100%' }}>
          <BooksLoadingContext.Provider value={[this.state.booksLoading, this.state.setBooksLoading]}>
            <FirebaseInitedContext.Provider value={[this.state.firebaseInited, this.state.setFirebaseInited]}>
              <CurrentUserContext.Provider value={[this.state.currentUser, this.state.setCurrentUser]}>
                <WriterProfileContext.Provider value={[this.state.writerProfile, this.state.setWriterProfile]}>
                  <CurrentTabContext.Provider value={[this.state.currentTab, this.state.setCurrentTab]}>
                    <CurrentBookContext.Provider value={[this.state.currentBook, this.state.setCurrentBook]}>
                    <BookListChangedContext.Provider value={[this.state.bookListChanged, this.state.setBookListChanged]}>
                      <Header />
                      <Switch>
                        <Route exact path='/' render={(props) => (<HomePage {...props} />)} />
                        <Route exact path='/myprofile' render={(props) => (<HomePage {...props} action="myprofile" tab="myprofile"/>)} />
                        <Route exact path='/mybooks/:sort?' render={(props) => (<HomePage {...props} action="mybooks" tab="mybooks"/>)} />
                        <Route exact path='/bookcard/:bookId?' render={(props) => (<HomePage {...props} action="bookcard" tab="mybooks" />)} />
                        <Route exact path='/promohistory/:bookId?' render={(props) => (<HomePage {...props} action="promohistory" tab="mybooks" />)} />
                        <Route exact path='/mybook/:bookId?' render={(props) => (<HomePage {...props} action="mybook" tab="mybooks" />)} />
                        <Route exact path='/mybook-promote/:bookId' render={(props) => (<HomePage {...props} action="mybook-promote" tab="mybooks" />)} />
                        <Route exact path='/mybook-sample/:bookId' render={(props) => (<HomePage {...props} action="mybook-sample" tab="mybooks"/>)} />
                        <Suspense fallback={<div></div>}>
                          <Route path='/contact/' render={(props) => (<ContactDirect contactOpen={true} locationState={props.location.state} />)} />

                          <Route path='/guide_faq/' render={(props) => (<StaticContentDirect contactOpen={true} contentType='guide_faq' locationState={props.location.state} />)} />
                          <Route path='/termsandcondition/' render={(props) => (<StaticContentDirect contactOpen={true} contentType='termsandcondition' />)} />
                          <Route path='/privacypolicy/' render={(props) => (<StaticContentDirect contactOpen={true} contentType='privacypolicy' />)} />                   
                             <Route path='/__/auth/'> <AuthHandler /></Route>
                        </Suspense>

                      </Switch>
                      <Suspense fallback={<CircularProgress />}>
                        <Footer />
                      </Suspense>
                      <Suspense fallback={<CircularProgress />}>
                        <FirebaseInit setCurrentUser={this.setCurrentUser} browserHistory={this.props.browserHistory} />
                      </Suspense>
                      <Backdrop style={{ zIndex: 15 }} open={booksLoading}>

                        {booksLoading && (
                          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                            <CircularProgress color="inherit" />
                          </div>

                        )}


                      </Backdrop>
                      </BookListChangedContext.Provider>
                    </CurrentBookContext.Provider>
                  </CurrentTabContext.Provider>
                </WriterProfileContext.Provider>
              </CurrentUserContext.Provider>
            </FirebaseInitedContext.Provider>
          </BooksLoadingContext.Provider>

        </Container>
      </MuiThemeProvider>
    );
  }
}

export default App;
