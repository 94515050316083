import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import bookService from '../book-service';
import BookImage from '../../../book-image/book-image.component';


export default function PromoHistoryDetails({detail,index}) {
    const id = 'hist'+detail.popularBookId;

    const [visible, setVisible] = useState();
    const [popularBook, setPopularBook] = useState();
    

    const callback = (entries, observer) => {
        if (!visible) {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    setVisible(true);
                    observer.unobserve(document.querySelector('div#'+id))
                }
            })
        }
    };

    React.useEffect(() => {
       
        if(detail){
            let observer = new IntersectionObserver(callback,  {
                // root: document.body,
                rootMargin: '0px',
                threshold: .10
            });
            observer.observe(document.querySelector('div#'+id));
        }
        

    }, [detail,index]);
    React.useEffect(() => {
        console.log(detail.popularBookId + "is visible "+visible)
        if(visible){
            bookService.getPopularBook(detail.popularBookId,setPopularBook)
        }

    }, [visible]);
    
    return (
       
           
                <Box sx={{display:'flex', p:2, border:'1px solid #eee', borderRadius:'8px',marginBottom:'1rem'}}>
                <div id={'hist'+detail.popularBookId} style={{minHeight:'300px'}}>
                    {popularBook && <BookImage book={popularBook} size="small"/>}
                    {!popularBook && <span>{detail.popularBookId}&nbsp;</span>}
                </div>
                <Box sx={{p:2}}>
                <Typography sx={{color:'#333'}} variant="h6"> Promo Dates:{detail.reservationStartDateStr} to {detail.reservationEndDateStr}</Typography>
                <Typography> {detail.whySimilar}</Typography>   
                <Typography > Review</Typography>   
                <Typography> {detail.reviewSummary}</Typography>   
                <Typography> {detail.review}</Typography>   
                    
                </Box>
                </Box>
                


            
            
            
        
    )


}
