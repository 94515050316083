import * as React from 'react';
import {
  Typography,
  Box,
  Button,
  CircularProgress
} from "@mui/material";
import 'react-quill/dist/quill.snow.css';
import authentication from '../../../services/authentication';
import WriterProfileContext from '../../../contexts/writer-profile/writer-profile.context';
import WriterAboutMeEdit from './writer-aboutme-edit-component';
import bookService from '../book/book-service';

export default function WriterAboutMe() {
  const [writerProfile, setWriterProfile] = React.useContext(WriterProfileContext);
  const [editing, setEditing] = React.useState(false);


  React.useEffect(() => {
    authentication.getIdToken().then((token) => {
      bookService.fetchProfile(token, setWriterProfile)
    });
  }, [editing])
  if (!writerProfile) return <Box><CircularProgress /></Box>

  const missingText = "......."

  return (<>


    <Typography sx={{ margin: '1rem 0', p: '1.5rem 1rem', backgroundColor: 'secondary.main' }} variant="h6">About Me</Typography>


    {!editing && (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={() => setEditing(true)}>Edit About Me</Button>
        </Box>

        <Box justifyContent='center'>
          <Typography variant="formLabel">Full Name: </Typography>

          {writerProfile.fullName ? (
            <Typography variant="formValue">{writerProfile.fullName}</Typography>
          ) : missingText}

          <Typography variant="formLabel" sx={{ marginTop: '1rem' }}>Your Bio</Typography>
          {writerProfile.bio ? (
            <Typography variant="formValue"  ><span className="editorHtmlSpan" dangerouslySetInnerHTML={{ __html: writerProfile.bio }} /> </Typography>
          ) : missingText}


          {writerProfile.profileLinks && writerProfile.profileLinks.map((pl,i) => {
            return <div key={i}>
            <Typography  variant="formLabel" sx={{ marginTop: '1rem' }}>{pl.linkTypeDisplay}</Typography>
            
            {pl.value ?
            getLink(pl.prefix + pl.value)
            : <Typography sx={{paddingLeft:'.5rem'}}>{missingText}</Typography>}
            </div>
          })}


          


          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={() => setEditing(true)}>Edit About Me</Button>
          </Box>
        </Box>
      </>
    )}
    {editing && (<WriterAboutMeEdit setEditing={setEditing} />)}


  </>
  );

}
function getLink(url) {
  return <a href={url} target="_blank" rel="noreferrer nofollow" > <Typography sx={{ textDecoration: 'underline',display:'inline' }} variant="formValue">{url}</Typography></a>

}