import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import throttle from 'lodash/throttle';
import { fetch as crossFetch } from 'cross-fetch';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';


const useStylesBookAutoCompleteTexField = makeStyles((theme,) => ({

  root: {
    overflow: 'hidden',
    borderRadius: '20px',
    backgroundColor: '#fcfcfb',
    borderColor:theme.palette.primary.main,
    borderWidth:'1px',
    borderStyle:'solid',
    
    '&:hover': {
      border: '0',
      backgroundColor: '#deded5',
    },
    '&$focused': {
      backgroundColor: '#eee'
    },
    '&:hover $notchedOutline': {
      border: '0',
    },
    '&$focused $notchedOutline': {
      border: '0',

    },

  },
  focused: {},
  notchedOutline: {},

}));

const useStylesBookAutoCompleteTexFieldLight = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    borderRadius: '20px',
    backgroundColor: '#fcfcfb',
    borderColor: theme.palette.accent2.main,
    borderWidth:'1px',
    borderStyle:'solid',
    
    
  },
  focused: {},
  notchedOutline: {},
}));
const useStyleCustom = makeStyles(() => ({

  renderOptions: {
    fontSize: 'smaller'
  }

}));




export default function SearchBooks(props) {
  const { isLight,defaultEmpty ,setSelectedBook} = props;
 
  const classesLight = useStylesBookAutoCompleteTexFieldLight();
  const classesRegular = useStylesBookAutoCompleteTexField();
  
  const classes = isLight?classesLight:classesRegular;
  const customClasses = useStyleCustom();


  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const placeHolder = 'Start typing to select a book';
  // const [autoTypeRunning, setAutoTypeRunning] = React.useState(false);

  const [options, setOptions] = React.useState([]);


  const urlBase = process.env.REACT_APP_URLBASE+'books/';


  const handleChange=(newValue)=>{
    setValue(newValue);
    if (!newValue) {
      return;
    }
    
    if (newValue.bookId) {
      setSelectedBook(newValue.bookId);
      setValue(null);
     
    }
   
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        (async () => {
          const response = await crossFetch(urlBase+ 'promotebooksearch?q=' + request.input);

          const books = await response.json();
          callback(books)
        })();


      }, 500),
    [],
  );
  
  React.useEffect(() => {
    let active = true;
    setOptions([]);//workaround for selected index
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        newOptions = [...results];
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  

  React.useEffect(()=>{
    if(defaultEmpty) setValue(null);

  },[defaultEmpty]);
   
  return (

    <>
      <Autocomplete
        id="book-search"
        //disableClearable
        //freeSolo
        //forcePopupIcon={false}
        //includeInputInList -- causes error with backspace
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.line1.replace(/<[^>]+>/g, ''))}
        filterOptions={(x) => x}
        options={options}
        includeInputInList
        filterSelectedOptions
        noOptionsText={inputValue===''?'Start typing':'No Matches'}
       // clearOnEscape
        value={value}
        onChange={(event, newValue) => {
          handleChange(newValue);
        
        }}
       
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (

          <TextField
            {...params}
            placeholder={placeHolder}//"Type in a book name to see recommedations"
            
            onKeyDown={e => {
              if (e.keyCode === 13 && e.target.value) {
                 handleChange(e.target.value);
              }
            }}
            variant="outlined"
            size="small"
            InputProps={
              {
                ...params.InputProps,
                classes,
                startAdornment: <InputAdornment position="end"><SearchIcon sx={{color:'accent2.main'}} /></InputAdornment>,
              }
            }
          />

        )

        }
        isOptionEqualToValue={(option, value) => option.bookId === value.bookId}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center" >
                <Grid item xs>
                  <Typography variant="body1" color="textPrimary" className={customClasses.renderOptions}>
                    <span dangerouslySetInnerHTML={{ __html: option.line1 }} />

                  </Typography>
                  <Typography variant="body1" color="textSecondary" className={customClasses.renderOptions}>
                    <span dangerouslySetInnerHTML={{ __html: option.line2 }} />

                  </Typography>
                  <Typography variant="body2" color="textSecondary" className={customClasses.renderOptions}>
                    <span dangerouslySetInnerHTML={{ __html: option.line3 }} />

                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}

      />
  
    </>

  );
}
