import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, useMediaQuery } from '@mui/material';
import { Link as RouteLink ,useLocation} from 'react-router-dom'
import { useTheme } from '@mui/material';
const imageSmallHeight = 160;
const imageSmallWidth = 105;

const imageLargeHeight = 190;
const imageLargeWidth = 130;



const useStyles = makeStyles((theme) => ({

  imageBase: {
    height: imageSmallHeight + 'px',
    width: imageSmallWidth + 'px',
    boxShadow: '8px 8px 8px 0 rgb(0 0 0 / 60%)',
    [theme.breakpoints.up('sm')]: {
      height: imageLargeHeight + 'px',
      width: imageLargeWidth + 'px',
      backgroundSize: imageLargeWidth + 'px ' + imageLargeHeight + 'px'
    },
  },
  seriesBase: {
    height: imageSmallHeight - 12 + 'px',
    width: imageSmallWidth - 12 + 'px',
    //boxShadow: '8px 12px 12px 0 rgb(0 0 0 / 60%)',
    borderLeft: '1px solid',
    [theme.breakpoints.up('sm')]: {
      height: imageLargeHeight - 12 + 'px',
      width: imageLargeWidth - 12 + 'px',
    },
  },
  seriesPrimary: {
    height: imageSmallHeight - 12 + 'px',
    width: imageSmallWidth - 12 + 'px',
    boxShadow: '8px 12px 12px 0 rgb(0 0 0 / 60%)',
    border: '1px solid red',
    zIndex: 2,

    [theme.breakpoints.up('sm')]: {
      height: imageLargeHeight - 12 + 'px',
      width: imageLargeWidth - 12 + 'px',
    },
    padding: '12px 0 0 12px',
  },
  seriesImage: {
    height: '18px',
    lineHeight: '18px',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '0px',
    backgroundPositionX: '-16px',
    backgroundImage: 'url(https://assets.meetnewbooks.com/series.webp)'
  },
  seriesText: {
    position: 'relative',
    background: '#ccc',
    // background: 'linear-gradient(180deg, #324d5a 0%, rgb(236 240 241) 100%)',
    borderColor: '#CCC',
    // backgroundColor: '#DDD',
    //borderStyle: 'solid',
    //borderWidth: '0 1px 1px 1px ',
    //borderRadius: '0 0 5px 5px',
    fontSize: '.6rem',
    lineHeight: '1rem',
    padding: '0',
    textAlign: 'center',
    verticalAlign: 'bottom',
    margin: '0',
    // width: imageSmallWidth + 'px',
    // [theme.breakpoints.up('sm')]: {
    //   width: imageLargeWidth  + 'px',
    // },
  }
}));

export default function BookImage(props) {
  const classes = useStyles();
  const location= useLocation();
  const theme = useTheme();
  const size = useMediaQuery(theme.breakpoints.up('sm'))?"medium":'small';
  const { book, nolinks ,hideSeriesImage} = props;

  const isSeries = !hideSeriesImage && book.series != null ? true : false;
  
  if(!book.cover){
    book.cover='/not_found'
  }
  const image = 'https://assets.meetnewbooks.com/covers/'+size+ book.cover;

  const showLink = !(Boolean(nolinks))
  const alt = isSeries ? book.series : book.title + ' by ' + book.author;
  return (
    <>
      <div>
        {isSeries && (
          <Container maxWidth={false}  disableGutters style={{ height: '16x', overflow: 'hidden', textAlign: 'left' }}>
            <div className={classes.seriesImage}>
              &nbsp;
            </div>
          </Container>
        )}
        {!showLink && (
          <picture>
          <source srcSet={image+'.webp'} type="image/webp"></source>
          <img  loading="lazy" className={classes.imageBase} src={image+'.jpeg'} alt={alt} />
          </picture>
        )}
        {showLink && (
          <RouteLink
            to={{
              pathname: '/book/' + book.idWithTitleSuffix,
              state: {
                fromLocation: location.pathname,
              }
            }}
          >
             <picture>
          <source srcSet={image +'.webp'} type="image/webp"></source>
          <img loading="lazy" className={classes.imageBase} src={image+'.jpeg'} alt={alt} />

          </picture>
            
            </RouteLink>
        )}

      </div>
    </>
  )
}