import * as React from 'react';
import {
  Typography,
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import 'react-quill/dist/quill.snow.css';
import { fetch as crossFetch } from 'cross-fetch';
import authentication from '../../../services/authentication';
import { useHistory } from 'react-router';


export default function BookDelete(props) {
  const { book } = props;
  const {bookId}= book;
  const published = Boolean(book && book.publishStatus==='published');

  const [deleteClicked, setDeleteClicked] = React.useState(false);
  const history = useHistory();


  const deleteBook = () => {

    const data = new URLSearchParams();


    authentication.getIdToken().then((token) => {
      data.append("idToken", token);
      data.append("bookId", bookId);
      setDeleteClicked(false);
      crossFetch(process.env.REACT_APP_URLBASE + "deleteWriterBook", {
        method: 'post',
        body: data
      }).then(res => {
        if (res.status >= 400) {
          window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
        }
        return res.json();
      }).then((t) => {
        //setBusy(false);
        var error = false;
        if (t !== 1) {
          window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
          error = true;
        }
        if (!error) {
          console.log("Window. window.openSnackbar" + window.openSnackbar)
          if (deleteClicked) {

            window.openSnackbar("Your book has been deleted");
            setDeleteClicked(false);
            history.push("/mybooks")

          }

        }

      }
      ).catch(function (e) {
        window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
        console.log("Got an error back" + e)
        //setBusy(false);
      });
    });
  }



  if (!bookId) return null;

  return (
    <>
      <Button variant="outlined" disabled={published} onClick={() => setDeleteClicked(true)}>Delete Book</Button> &nbsp;

      <Dialog open={deleteClicked}>
        <DialogTitle>Delete Book?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setDeleteClicked(false)}>Cancel</Button> &nbsp;
          <Button variant="contained" onClick={deleteBook}>DELETE</Button>
        </DialogActions>
      </Dialog>



    </>
  )

  }
