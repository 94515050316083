import { Typography, Box, Button, Grid, Breadcrumbs, useTheme, useMediaQuery } from '@mui/material';
import React, { useContext, useState } from 'react';
import PromoteBook from '../book/promote/book-promote.component';
import BookValidationStatus from './book-validation-status.component';
import { useHistory } from 'react-router-dom'
import BookPublishStatus from './book-publish-status.component';
import bookService from '../book/book-service';
import BooksLoadingContext from '../../../contexts/books-loading/books-loading.context';
import { WarningAmber } from '@mui/icons-material';
import BookListChangedContext from '../../../contexts/book-list/book-list-changed.context';


export default function BookCard({ bookId }) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    const [book, setBook] = useState(null);
    const history = useHistory();
    const [, setBusy] = useContext(BooksLoadingContext);
    const [changed, setChanged] = React.useContext(BookListChangedContext)
    


    React.useEffect(() => {
        setBusy(true);
        if (bookId) {
            bookService.fetchBook(bookId, setBook);

            window.scrollTo(0, 0);
            setBusy(false);
            if(changed) setChanged(false);
        }
    }, [bookId,changed])




    if (!book) return null;

    const similarBooks = book.similarBooks ? book.similarBooks : [];

    for (var i = 0; i < 3; i++) {
        if (!similarBooks[i]) {
            similarBooks.push({ bookId: book.bookId })
        }
    }

    const hasErrors = (book.detailsComplete && book.detailsComplete.length>0) ||
                        (book.similarBooksComplete && book.similarBooksComplete.length>0) ||
                        (book.promoExpired)

    
    

    return (
        <Box sx={{ maxWidth: 'lg' }}>

            {/* <Button startIcon={<ArrowBack />} onClick={() => history.goBack()}>Back</Button> */}
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom:'.5rem'}}>
     
     <Button sx={{textTransform:'none'}} onClick={()=>history.push("/mybooks")}> My Books </Button>
     <Typography variant="formValue"> {book.title?book.title:'Untitled'} </Typography>
     
    </Breadcrumbs>

            {(hasErrors) && (

                <Grid item xs={12} sx={{ margin: '0',p:'1rem 0 1rem 2rem',backgroundColor:'#fff59d70', borderRadius:'8px' }}>
                {book.detailsComplete && book.detailsComplete.map((m,i)=>{
                    return <Typography component="div" sx={{fontSize:'.9rem'}} key={i}>
                            <div style={{display:'inline-flex', verticalAlign:'middle'}}>
                            <WarningAmber sx={{color:'#af8625',fontSize:'20px'}}/>&nbsp;
                            </div>
                         {m}</Typography>;
                         
                })}
                {book.similarBooksComplete && book.similarBooksComplete.map((m,i)=>{
                    return <Typography  component="div" sx={{fontSize:'.9rem'}}  key={i}>
                        <div style={{display:'inline-flex', verticalAlign:'middle'}}>
                            <WarningAmber sx={{color:'#af8625',fontSize:'20px'}}/>&nbsp;
                            </div>
                        {m}</Typography>;
                })}
                {book.promoExpired &&(
                    <Typography component="div" sx={{fontSize:'.9rem'}}> 
                    <div style={{display:'inline-flex', verticalAlign:'middle'}}>
                            <WarningAmber sx={{color:'#af8625',fontSize:'20px'}}/> &nbsp;
                            </div>
                    
                    {book.promoExpired}</Typography>
                )}
                </Grid>



            )}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ margin: '0', }}>


                <Box sx={{ display: 'flex', width: '100%', p: '0', m: '1rem 0', backgroundColor: 'secondary.main', }}>
                    <Typography variant="h5" noWrap sx={{ p: '1rem', fontWeight: 'bold', width: "100%", color:'secondary.contrastText' }}> {book.title ? book.title : '<No title>'}</Typography>

                </Box>

                <Box sx={{
                    display: 'flex', alignContent: 'flex-start', alignItems: 'flex-start', justifyContent: { xs: 'center', sm: 'center', md: 'left', lg: 'left' },

                    flexWrap: isSmall ? 'wrap' : 'nowrap', paddingLeft: isSmall ? '0' : '1rem'
                }}>

                    {bookImageArea()}
                    <Box sx={{ padding: '1rem', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <BookPublishStatus book={book} />
                    </Box>


                </Box>
                <Box sx={{ flexGrow: 1, minWidth: 'sm', paddingTop: isSmall ? '1rem' : '0' }}>
                    {/* {similarBooksArea()} */}
                    <PromoteBook bookId={bookId}/>
                </Box>



            </Grid>
        </Box>);

    function bookImageArea() {
        return <Box sx={{ maxWidth: '250px', textAlign: 'center', p: '1rem 0 0 0' }}>
            {!book.coverImage && (
                <img alt="No Cover" src={'https://assets.meetnewbooks.com/nocoveruploaded.png'}
                    style={{ width: '130px', height: '190px', border: '1px solid #999', boxShadow: '8px 8px 8px 0 rgb(0 0 0 / 60%)', margin: '0', opacity: .7 }} />)}
            {book.coverImage && (
                <img alt="Book Cover" src={'https://assets.meetnewbooks.com/' + book.coverImage} style={{
                    width: '130px', height: '190px', border: '1px solid #999',
                    boxShadow: '8px 8px 8px 0 rgb(0 0 0 / 60%)', margin: '0'
                }} />)}

            <Box sx={{ width: '250px', display: 'flex', flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'center', md: 'left', lg: 'left' }, alignItems: 'center', padding: '1rem 0 0 0' }}>
                {detailsButtonsArea()}
            </Box>

        </Box>;
    }


    function detailsButtonsArea() {


        return <>

            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '0', width: '100%' }}>
                <Button size="small" sx={{ width: '12ch' }} variant="contained" onClick={() => {
                    history.push("/mybook/" + book.bookId);
                }}> Details</Button>

                <BookValidationStatus messages={book.detailsComplete} title='' />


            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1rem 0 0 0', width: '100%' }}>
                <Button size="small" sx={{ width: '12ch' }} variant="contained" onClick={() => {
                    history.push("/mybook-sample/" + book.bookId);
                }}>Excerpt </Button>

                <BookValidationStatus messages={book.excerptComplete} title='' />


            </Box>



        </>;
    }
}
