import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Card }
  from '@mui/material';
import * as React from 'react';
import 'react-quill/dist/quill.snow.css';
import WriterProfileContext from '../../../contexts/writer-profile/writer-profile.context';
import ShareButtons from '../../share/share-buttons-component';
import Switch from '@mui/material/Switch';
import authentication from '../../../services/authentication';
import { fetch as crossFetch } from 'cross-fetch';
import HelpPopover from '../../help/help-popover';

export default function WriterSiteCard(props) {
  const [writerProfile, setWriterProfile] = React.useContext(WriterProfileContext);
  const [profileOnOffClicked, setProfileOnOffClicked] = React.useState(false);
  const [hideProfile, setHideProfile] = React.useState(writerProfile.hideProfile);

  React.useEffect(() => {
    if (writerProfile) {

      setHideProfile(writerProfile.hideProfile)
    }

  }, [writerProfile,profileOnOffClicked])
 

  if (!writerProfile) return null;


  const isWebsiteReady = Boolean(writerProfile.fullName);

  const updateProfile = (hideProfile) => {
    authentication.getIdToken().then((token) => {
      const data = new URLSearchParams();
      data.append("idToken", token);
      data.append("hideProfile", hideProfile);
      // data.append("delete", Boolean(deleteClicked));

      crossFetch(process.env.REACT_APP_URLBASE + "updateWriterProfileState", {
        method: 'post',
        body: data
      }).then(res => {
        if (res.status >= 400) {
          window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
        }
        return res.json();
      }).then((t) => {
        //setBusy(false);
        var error = false;
        if (!t || !t.accountId) {
          window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
          error = true;
        }
        if (!error) {

          if (window.openSnackbar) {
            window.openSnackbar("Your changes have been saved");

            setWriterProfile(t)
            setProfileOnOffClicked(false);
          }

        }

      }
      ).catch(function (e) {
        window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
        console.log("Got an error back" + e)
        //setBusy(false);
      });
    });
  }

  const title = "Meet New Books Author: " + writerProfile.fullName;

  const url = isWebsiteReady ? process.env.REACT_APP_WRITER_AUTHOR_URLBASE + writerProfile.accountId + "/" + writerProfile.fullName.toLowerCase().replace(/[^a-zA-Z0-9']+/g, "-") : null;
  const displayUrl = isWebsiteReady ? "https:/www.meetnewbooks.com/author/"+ writerProfile.accountId + "/" + writerProfile.fullName.toLowerCase().replace(/[^a-zA-Z0-9']+/g, "-") : null;

  return (<>
    <Box sx={{ backgroundColor: '#FFF', width: '100%',position:'relative' }}>
      <Box sx={{position:'absolute', right:0, top:0}}>
      <HelpPopover topic="authorprofile"/>
      </Box>
      <Box sx={{ p: 1 }}>{/*strange but this is needed for the noWrap belowto work on small screens with a long url*/}
        <Typography variant="h6" sx={{ width: '100%', textAlign: 'center', color: 'primary.main', p: '1rem 0 .5rem 0' }}>
          Public Profile 
        </Typography>
        {isWebsiteReady && (

          <Typography component="div" sx={{ wordBreak: 'break-all', width: '100%', textAlign: 'center' }} >
            {!hideProfile &&(<a href={url} rel="nofollow noreferrer" target="_blank">{displayUrl}</a>)}
            {hideProfile &&(<span style={{textDecoration:'line-through',color:'#CCC'}}>{displayUrl}</span>)}
          </Typography>
        )}
        {!isWebsiteReady && (
          <Typography component="div"
           //sx={{ maxWidth: { xs: '290px', sm: '350px', md: '670px', lg: '750px', textAlign: 'center' } }}
           sx={{ width: '100%' , textAlign: 'center' } }
            >
            Not ready. Please complete your profile to view your author page
            <div style={{fontSize:'.8rem'}}> The only required field is your full name. Please fill it carefully, it cannot be changed once you save it. </div>
            <div style={{fontSize:'.8rem'}}> All other fields are optional (including the profile picture). We recommend filling out as many of these as you can. </div>
            
            
          </Typography>
        )}
      </Box>
      {isWebsiteReady && (
        <>

          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignContent: 'flex-end', alignItems: 'center' }}>

            <Typography sx={{ width: '100%', textAlign: 'center' }} variant="h6">
              Profile visible to public:
            </Typography>

            <Switch sx={{}} checked={!hideProfile} onChange={() => {
              setProfileOnOffClicked(true)
            }} />
            <Typography variant="caption">{!hideProfile?'YES':'NO'}</Typography>
          </Box>
          {profileOnOffClicked && (
            <Dialog open={profileOnOffClicked}>
              <DialogTitle>Confirm</DialogTitle>
              <DialogContent>
                {writerProfile.hideProfile ? 'Make your profile public?' : 'Hide your profile from public view? This will hide all your data, including books. Any books posted to meetnewbooks.com will also be hidden'}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {

                  // setHideProfile(writerProfile.hideProfile);
                  setProfileOnOffClicked(false);
                  //setHideProfile(!hideProfile);
                }}> Cancel</Button>
                <Button variant="contained" onClick={() => updateProfile(!hideProfile)}>Yes, Update</Button>
              </DialogActions>
            </Dialog>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
          
          
          
          <Card elevation={3} sx={{width:'auto',p:1}}>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', }}>
            <Typography variant="notice" sx={{fontSize:'1.3rem'}}>
              SHARE IT!
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', }}>
            <ShareButtons url={url} title={title} disabled={hideProfile}/>
          </Box>
          </Card>
          </Box>
        </>

      )}

    </Box>

  </>
  );
}