import React, { useState } from "react";
import { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import authentication from "../../../../services/authentication";
import { fetch as crossFetch } from "cross-fetch";
import {
  Box,
  Typography,
  Button,
  TextField,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Checkbox,
} from "@mui/material";

import { CircularProgress } from "@material-ui/core";
import TagsAutoComplete from "./tags.component";
import BookListChangedContext from "../../../../contexts/book-list/book-list-changed.context";
import bookService from "../book-service";
import HelpPopover from "../../../help/help-popover";
import QualitativeRating from "./qualitative-rating.component";

const qualitativeRatingOptions = [
  { value: 5, label: "Incredible" },
  { value: 4, label: "Loved It" },
  { value: 3, label: "Liked It" },
  { value: 2, label: "Did Not Like" },
  { value: 1, label: "Hated It" },
];
export default function PopularBookReviewDialog(props) {
  const { book, popularBookId, onSave, similarBook } = props;
  const [checked, setChecked] = React.useState(false);
  const [schedule, setSchedule] = React.useState(null);
  const [, setChanged] = useContext(BookListChangedContext);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const [popularBook, setPopularBook] = useState(null);

  const cancelClicked = () => {
    onSave(null);
  };

  const [review, setReview] = useState("");
  // const [reviewSummary, setReviewSummary] = useState("");
  const [rating, setRating] = useState(null);

  const [whySimilar, setWhySimilar] = useState("");

  const MAX_CHARS_REVIEW = 2500;
  const MIN_CHARS_REVIEW = 100;

  const MAX_CHARS_WHY_SIMILAR = 500;
  const MIN_CHARS_WHY_SIMILAR = 100;

  const [errors, setErrors] = useState(null);

  const saveSimilarBook = () => {
    setErrors(null);
    const errors = {};
    const reviewChars = review ? review.length : 0;
    if (reviewChars > MAX_CHARS_REVIEW) {
      errors["review"] =
        "You have exceeded the maximum characters (" +
        MAX_CHARS_REVIEW +
        ") allowed " +
        reviewChars;
    }
    if (reviewChars < MIN_CHARS_REVIEW) {
      errors["review"] =
        "Please enter at least " + MIN_CHARS_REVIEW + " characters ";
    }
    const whySimilarChars = whySimilar ? whySimilar.length : 0;
    if (whySimilarChars > MAX_CHARS_WHY_SIMILAR) {
      errors["whySimilar"] =
        "You have exceeded the maximum characters (" +
        MAX_CHARS_WHY_SIMILAR +
        ") allowed " +
        whySimilarChars;
    }
    if (whySimilarChars < MIN_CHARS_WHY_SIMILAR) {
      errors["whySimilar"] =
        "Please enter at least " + MIN_CHARS_WHY_SIMILAR + " characters ";
    }
    if (!rating) {
      errors["rating"] = "Please select a rating";
    }
    // if (!reviewSummary || reviewSummary.length < 3) {
    //   errors["reviewSummary"] = "Please enter at least 3 tags";
    // }
    // if (reviewSummary.length > 5) {
    //   errors["reviewSummary"] = "Please enter at most 5 tags";
    // }
    const errorKeys = Object.keys(errors);
    if (errorKeys && errorKeys.length > 0) {
      console.log("Has Errors");
      setErrors(errors);
      const errorNode = document.getElementById("error_" + errorKeys[0]);
      if (errorNode) {
        errorNode.scrollIntoView();
      }
      return;
    } else {
      sendSaveRequest();
    }
  };

  const sendSaveRequest = () => {
    const data = new URLSearchParams();

    data.append("review", review);
    data.append("reviewChars", review.length);
    // const arr = reviewSummary ? reviewSummary.join("\n") : "";
    // data.append("reviewSummary", arr);
    data.append("rating", rating);
    data.append("whySimilar", whySimilar);
    data.append("whySimilarChars", whySimilar.length);
    data.append("bookId", book.bookId);
    data.append("popularBookId", popularBookId);
    data.append("similaritySequence", similarBook.similaritySequence);

    authentication.getIdToken().then((token) => {
      data.append("idToken", token);
      // data.append("delete", Boolean(deleteClicked));

      crossFetch(process.env.REACT_APP_URLBASE + "saveWriterSimilarBook", {
        method: "post",
        body: data,
      })
        .then((res) => {
          if (res.status >= 400) {
            window.openSnackbar(
              "Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later"
            );
          }
          return res.json();
        })
        .then((t) => {
          //setBusy(false);
          var error = false;
          if (!t || !t.bookId) {
            window.openSnackbar(
              "Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later"
            );
            error = true;
          }
          if (!error) {
            window.openSnackbar("Your changes have been saved");
            cancelClicked();
          }
          setChanged(true);
        })
        .catch(function (e) {
          window.openSnackbar(
            "Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later"
          );
          console.log("Got an error back" + e);
          //setBusy(false);
        });
    });
  };
  const showErrors = (key) => {
    if (errors && errors[key])
      return (
        <Typography id={"error_" + key} color="red">
          {errors[key]}
        </Typography>
      );
    return null;
  };
  const getPopularBook = (bookId) => {
    const url =
      process.env.REACT_APP_URLBASE + "books/book?type=book&id=" + bookId;
    crossFetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.id) {
          setPopularBook(data);
        }
      })
      .catch(function (e) {
        console.log("Got an error back" + e);
        window.gtag("event", "exception", {
          description: e + " url " + url,
          fatal: false, // set to true if the error is fatal
        });
      })
      .finally(() => {});
  };

  React.useEffect(() => {
    if (popularBookId) {
      getPopularBook(popularBookId);
    }
    if (similarBook.schedule && similarBook.schedule.expired) {
      // setReviewSummary([]);
      setReview("");
      setRating(null);
      setWhySimilar("");
    } else {
      // const arr = similarBook.reviewSummary
      //   ? similarBook.reviewSummary.split("\n")
      //   : [];
      // setReviewSummary(arr);
      setReview(similarBook.review);
      setRating(similarBook.rating);
      setWhySimilar(similarBook.whySimilar);
    }
    authentication.getIdToken().then((token) => {
      bookService.fetchSchedule(token, popularBookId, book.bookId, setSchedule);
    });
    // setSimilaritySequence(similarBook.similaritySequence);
  }, [book.bookId, popularBookId, similarBook]);

  return (
    <Dialog
      open={Boolean(popularBookId)}
      fullScreen={isSmall}
      maxWidth={isSmall ? "xl" : "lg"}
    >
      {popularBook && (
        <>
          <DialogTitle>
            Help Readers discover your book{" "}
            <HelpPopover topic="discoverability" />
          </DialogTitle>
          <DialogContent>
            <Box sx={{ p: "0" }}>
              <Typography
                sx={{ fontSize: ".8rem", p: 1, backgroundColor: "#ddd8ab54" }}
                component="div"
              >
                When a reader views <b>{popularBook.title}</b>, we can recommend{" "}
                <b>{book.title}</b> to them.
                {schedule && schedule.status == "tentative" && (
                  <>
                    Tentative Schedule: <b>{schedule.reservationStartDate}</b>{" "}
                    to <b>{schedule.reservationEndDate}</b>. We will confirm the
                    exact dates once you submit <b>{book.title}</b> on
                    MeetNewBooks.com
                  </>
                )}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginTop: "1rem" }}>
              <Typography variant="formLabel">
                Your thoughts (Comments/Review) on <b>{popularBook.title}</b>
              </Typography>
              <Typography variant="caption">
                (Opportunity - If your target readers like your review, it
                increases the chances they may try your book.)
              </Typography>
              <Typography variant="caption" component="div">
                Leave an honest, genuine opinion. Do not mention your book in
                the review. We permanently delete accounts that do not comply
                with the terms of service.
              </Typography>

              {showErrors("review")}
              <TextField
                defaultValue={review ? review : ""}
                multiline
                fullWidth
                minRows={4}
                maxRows={8}
                onChange={(e) => setReview(e.target.value)}
              />
              <Typography sx={{ fontSize: ".8rem" }}>
                {(review && review.length) || 0}/{MAX_CHARS_REVIEW}
              </Typography>
            </Box>

            <Box sx={{ width: "100%", marginTop: "1rem" }}>
              <Typography variant="formLabel">
                Why would fans of <b>{popularBook.title} </b> enjoy{" "}
                <b>{book.title}</b>?
              </Typography>
              <Typography variant="caption">
                (Highlight the similarities, feel free to point out where you
                think you did better ) <br />
                <b style={{ backgroundColor: "#ddd8ab54" }}>
                  (Please make sure you have read <b>{popularBook.title}</b>{" "}
                  before answering this question, and that your book is truly
                  similar. If not, please select a different book )
                </b>
              </Typography>

              {showErrors("whySimilar")}
              <TextField
                defaultValue={whySimilar ? whySimilar : ""}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                onChange={(e) => setWhySimilar(e.target.value)}
              />

              <Typography sx={{ fontSize: ".8rem" }}>
                {(whySimilar && whySimilar.length) || 0}/{MAX_CHARS_WHY_SIMILAR}
              </Typography>
            </Box>

            {/* <Box sx={{ p: 1 }}> 
              <Typography variant="formLabel">
                Tags/summary of your thoughts on <b>{popularBook.title} </b>{" "}
              </Typography>
              <Typography variant="caption">
                (Choose from the list, or enter your own)
              </Typography>
              {showErrors("reviewSummary")}
              <TagsAutoComplete
                onChange={setReviewSummary}
                helperText="Start typing. Hit Enter to select/insert"
                value={reviewSummary}
              />
            </Box> */}
            <Box sx={{ width: "100%", marginTop: "1rem" }}>
              <Typography variant="formLabel">
                Rating of <b>{popularBook.title}</b>
              </Typography>
              {showErrors("rating")}
              <QualitativeRating
                defaultValue={rating}
                onChange={setRating}
                elements={qualitativeRatingOptions}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ flexWrap: "wrap" }}>
            <Box
              sx={{
                p: 1,
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <Checkbox checked={checked} onChange={handleChange} />
              <Typography variant="caption" component="div">
                You agree to our terms of service, privacy policy and allow us
                to post this content on meetnewbooks.com.
                <br /> You understand we may remove your content if we determine
                it is not appropriate the users of meetnewbooks.com{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                p: 1,
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button onClick={cancelClicked}>Cancel</Button>
              <Button
                color="action"
                disabled={!checked}
                variant="contained"
                onClick={saveSimilarBook}
              >
                Save
              </Button>
            </Box>
          </DialogActions>
        </>
      )}
      {!popularBook && <CircularProgress />}
    </Dialog>
  );
}
