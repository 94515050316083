import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress} from "@mui/material";
import 'react-quill/dist/quill.snow.css';
import { fetch as crossFetch } from 'cross-fetch';
import authentication from '../../../../services/authentication';

import HtmlEditor from '../../html-editor.component';
import CurrentBookContext from '../../../../contexts/current-book/current-book.context';
import BooksLoadingContext from '../../../../contexts/books-loading/books-loading.context';



export default function BookSampleEdit(props) {
  const [book, setBook] = React.useContext(CurrentBookContext);
  const [, setBooksLoading] = React.useContext(BooksLoadingContext);
  const [sample] = React.useState(book && book.sample ? book.sample : { bookId: book.bookId });
  const [chapterChars, setChapterChars] = React.useState();

  const [chapter, setChapter] = React.useState(sample ? sample.chapter : '');

  const { setEditing } = props;

  const saveSample = () => {
    if (chapterChars > 10000) {
      window.openSnackbar("You have exceeded the maximum number of characters allowed")
      return;
    }

    const data = new URLSearchParams();
    for (const pair of new FormData(document.getElementById("sampleForm"))) {
      data.append(pair[0], pair[1]);
    }
    setBooksLoading(true);
    authentication.getIdToken().then((token) => {
      data.append("idToken", token);
      data.append("chapterChars", chapterChars);
      //   data.append("delete", Boolean(deleteClicked));

      crossFetch(process.env.REACT_APP_URLBASE + "saveSample", {
        method: 'post',
        body: data
      }).then(res => {
        if (res.status >= 400) {
          window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
        }
        return res.json();
      }).then((t) => {
        setBooksLoading(false);
        var error = false;
        if (!t || !t.bookId) {
          window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
          error = true;
        }
        if (!error) {
          if (window.openSnackbar) {
            window.openSnackbar("Your changes have been saved");

          }
          setEditing(false)
          setBook(t);
        }

      }
      ).catch(function (e) {
        window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
        console.log("Got an error back" + e)
        setBooksLoading(false);
      });
    });
  }

  
  if (!sample) return <Box><CircularProgress /></Box>
  return (<Box>
    <form id="sampleForm">
      <input type="hidden" name="chapter" value={chapter ? chapter : ''} />
      <input type="hidden" name="bookId" value={book.bookId} />
      <Box justifyContent='center'>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: '1rem 0' }}>
          <Button variant="outlined" onClick={() => setEditing(false)}>Cancel</Button> &nbsp;
          <Button color="action" variant="contained" onClick={saveSample}>Save</Button>
        </Box>
        <HtmlEditor defaultValue={sample.chapter} setHtml={setChapter} maxChars={10000} setTotalChars={setChapterChars} />



      </Box>
    </form>

  </Box>)

}