import * as React from 'react';
import {
  Typography,
  Box,
  Button,
  TextField, CircularProgress
} from "@mui/material";
import 'react-quill/dist/quill.snow.css';
import { fetch as crossFetch } from 'cross-fetch';
import authentication from '../../../services/authentication';
import WriterProfileContext from '../../../contexts/writer-profile/writer-profile.context';
import WriterProfileLink from './writer-profile-link.component';
import HtmlEditor from '../html-editor.component';
import BooksLoadingContext from '../../../contexts/books-loading/books-loading.context';


export default function WriterAboutMeEdit(props) {
  const { setEditing } = props;

  const [writerProfile, setWriterProfile] = React.useContext(WriterProfileContext);
  const [, setBooksLoading]= React.useContext(BooksLoadingContext)
  const [bioHtml, setBioHtml] = React.useState();
  const [bioChars, setBioChars] = React.useState();


  const saveProfile = () => {

    const data = new URLSearchParams();
    if (bioChars > 1000) {
      window.openSnackbar("You have exceeded the maximum number of characters allowed for your bio");
      return;
    }
    for (const pair of new FormData(document.getElementById("writerProfileForm"))) {
      data.append(pair[0], pair[1]);
    }
    if(bioHtml){
      data.append("bio", bioHtml);
    }
    
    data.append("bioChars", bioChars);
    const profileLinks=  writerProfile.profileLinks;
    if(profileLinks) {
      for (let x = 0; x < profileLinks.length; x++) {
          if(profileLinks[x].value) {
              data.append(profileLinks[x].linkType, profileLinks[x].value);
          }
      }
  }

    setBooksLoading(true);
    authentication.getIdToken().then((token) => {
      data.append("idToken", token);

      crossFetch(process.env.REACT_APP_URLBASE + "saveProfile", {
        method: 'post',
        body: data
      }).then(res => {
        if (res.status >= 400) {
          window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
        }
        return res.json();
      }).then((t) => {
        //setBusy(false);
        setBooksLoading(false);
        var error = false;
        if (t !== 1) {
          window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
          error = true;
        }
        if (!error) {

          if (window.openSnackbar) {
            window.openSnackbar("Your changes have been saved");
          }
          setEditing(false);
          //setWriterProfile(writerProfile);
        }

      }
      ).catch(function (e) {
        window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
        console.log("Got an error back" + e)
        setBooksLoading(false);
        //setBusy(false);
      });
    });
  }
  const fetchProfile = (token) => {
    setBooksLoading(true);
    const url = process.env.REACT_APP_URLBASE + "getProfile?idToken=" + token;
    crossFetch(url)
      .then(res => {
        return res.json();
      })
      .then((data) => {
        setWriterProfile(data);
        setBioHtml(data.bio);
        setBioChars(data.bioChars || 0);
        setBooksLoading(false);
      }
      ).catch(function (e) {
        console.log("Got an error back" + e)
        window.gtag('event', 'exception', {
          'description': e + ' url ' + url,
          'fatal': false   // set to true if the error is fatal

        });
        setBooksLoading(false);
      })
  }
  React.useEffect(() => {
    authentication.getIdToken().then((token) => {
      fetchProfile(token)
    });
  }, [])
  if (!writerProfile) return <Box><CircularProgress /></Box>

  const buttons = <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', p: '0 0 1rem 0' }}>
    <Button size="small" variant="outlined" onClick={() => setEditing(false)}>Cancel</Button>&nbsp;
    <Button size="small" variant="contained" onClick={saveProfile}>Save Changes</Button>
  </Box>;
  return (<>

    {buttons}

    <form id="writerProfileForm">
      <div style={{ paddingBottom: '1rem' }}>
      {(!writerProfile.fullName || writerProfile.fullName.length === 0) && (
          <TextField
            InputLabelProps={{ shrink: true }}
            size="small" label="Full Name"
            type="text" helperText={<span style={{color:'red', fontWeight:'bold'}}> Your name cannot be edited once you save this form. Please make sure you fill it correctly.</span>}
            name="fullName" defaultValue={writerProfile.fullName} />
        )}
        {(writerProfile.fullName && writerProfile.fullName.length > 0) && (
          <>
            <Typography variant="formLabel">Full Name: </Typography>
      

            <Typography variant="formValue">{writerProfile.fullName}</Typography>

          </>
        )}
      </div>


      <Typography sx={{ color: '#333' }}>Bio/About You</Typography>
      <HtmlEditor defaultValue={writerProfile.bio} setHtml={setBioHtml} setTotalChars={setBioChars} maxChars={1000} />


      <Typography variant="h6" sx={{ width: '100%', textAlign: 'left', p: 1, color: '#333' }}> The fields below are optional but recommended </Typography>
      <Typography variant="caption" sx={{ width: '100%', textAlign: 'left', p: 1, color: '#333' }}> If you can't fill a field, it's fine to leave it blank. You can always come back and enter it later </Typography>

      <Box>
      <Typography  variant="formLabel" > Social Media Links</Typography>
       {writerProfile.profileLinks && writerProfile.profileLinks.map((pl,i)=>{
         return  <WriterProfileLink link={pl} key={i} profileLinks={writerProfile.profileLinks}/>
       })}   
      </Box>
      {/* <WriterProfileLink label="Your Twitter Username" fieldName="twitter" placeholder="username"
        urlPrefix="https://www.twitter.com/" value={writerProfile.twitter} helperText="Your twitter username without the @" />

      <WriterProfileLink label="Your Instagram Username" fieldName="instagram" placeholder="username"
        urlPrefix="https://www.instagram.com/" value={writerProfile.instagram} helper="Your instagram username" />
      <WriterProfileLink label="Your Facebook Page" fieldName="facebook" placeholder="your facebook page"
        urlPrefix="https://www.facebook.com/" value={writerProfile.facebook} helper="Your facebook page" />

      <WriterProfileLink label="Your Youtube channel" fieldName="youtube" placeholder="username"
        urlPrefix="https://www.youtube.com/channel/" value={writerProfile.youtube} /> */}


      {/* skipping amazon for now since it may have a country specific link .com, .uk etc.
            <WriterProfileLink label="Your Amazon.com Author Page" fieldName="amazon" placeholder="your website address"
                  urlPrefix="https://www.amazon.com/author" value={writerProfile.amazon} /> */}


      {/* <WriterProfileLink label="Your GoodReads Author Page" fieldName="goodreads" placeholder="your goodreads author page"
        urlPrefix="https://www.goodreads.com/author/" value={writerProfile.goodreads} /> */}

      {/* <WriterProfileLink label="Your Website/Blog" fieldName="website" placeholder="your website address"
        urlPrefix="https://" value={writerProfile.website} /> */}

    </form>
    {buttons}
  </>
  );
}