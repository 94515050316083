import React,{useContext} from 'react';
import Typography from '@mui/material/Typography';
import {Button, DialogActions,Dialog, DialogTitle} from '@mui/material';
import WriterProfileContext from '../../contexts/writer-profile/writer-profile.context';
import authentication from '../../services/authentication';
import { DialogContent } from '@material-ui/core';
import BooksLoadingContext from '../../contexts/books-loading/books-loading.context';
import bookService from '../writer-book/book/book-service';

export default function AcceptTerms ({user}){
    const [, setBooksLoading]= React.useContext(BooksLoadingContext)
    const [writerProfile, setWriterProfile]= useContext(WriterProfileContext);
    const saveAcceptTerms=(token)=>{
        const data = new URLSearchParams();
        data.append("idToken", token);
    
          fetch(process.env.REACT_APP_URLBASE + "acceptedTerms", {
            method: 'post',
            body: data
          }).then(res => {
            if (res.status >= 400) {
              window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
            }
            return res.json();
          }).then((t) => {
            //setBusy(false);
            setBooksLoading(false);
            var error = false;
            if (t !== 1) {
              window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
              error = true;
            }
            if (!error) {
              bookService.fetchProfile(token,setWriterProfile);
              if (window.openSnackbar) {
                window.openSnackbar("Your changes have been saved");
              }
              
              
            }
    
          }
          ).catch(function (e) {
            window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
            console.log("Got an error back" + e)
            setBooksLoading(false);
            
          });
       
      }
      const acceptTerms = () => {
        authentication.getIdToken().then((token) => {
            saveAcceptTerms(token)
           });
        
      }

    console.log("writerProfile.acceptedTerms:"+writerProfile.acceptedTerms)
    if(user && !writerProfile.acceptedTerms){
        return <Dialog open={!writerProfile.acceptedTerms}>
            <DialogTitle>
                Agree to Terms and Conditions
              </DialogTitle>
            <DialogContent>
                <Typography>
                   Please confirm you have reviewed and agree to our <a href="/termsandcondition" target="_blank" rel="noreferrer noopener">terms and conditions</a>
                </Typography>
                
            </DialogContent>
          <DialogActions>
              <Button variant="contained" onClick={()=>authentication.signOut()}> No, sign me out </Button>
              <Button variant="contained" onClick={acceptTerms} > Yes, I agree </Button>
          </DialogActions>
        </Dialog>
      }
}