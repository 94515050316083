import React from 'react';
import { Box, Button, IconButton } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Twitter, Facebook, ContentCopy } from '@mui/icons-material';


export default function ShareButtons(props) {
    const { title, url ,disabled} = props;

    const canShare = window.navigator && window.navigator.share;
    const share = () => {
        if (window.navigator && window.navigator.share) {
            window.navigator.share({ title: title, url: url })
        }
    }
    const copyLink = () => {
        if (window.navigator && window.navigator.clipboard) {
            window.navigator.clipboard.writeText(url);
            if (window.openSnackbar) {
                window.openSnackbar("Copied link to clipboard");
            }
        }
    }


    return (
        <>
            <Box sx={{ p: 1 }}>

                <a href={"https://facebook.com/sharer/sharer.php?u=" + url} target='_blank' rel="nofollow noopener noreferrer">
                    <Button size="small" disabled={disabled} sx={{ backgroundColor: '#3c5a99', borderColor: '#3c5a99' }} variant="contained" startIcon={<Facebook />}>Facebook</Button>
                </a>
            </Box>
            <Box sx={{ p: 1 }}>

                <a  href={"https://twitter.com/intent/tweet?url=" + url + "&text=" + title + "&via=meetnewbooks&hashtags=BookTwitter,books,booklovers,readers,goodreads,readingcommunity,Bookish,BookRecommendations,BookSuggestions"}
                    style={{ textDecoration: 'none' }} target='_blank' rel="nofollow noopener noreferrer">
                    <Button size="small" disabled={disabled} sx={{ backgroundColor: '#1da1f2', borderColor: '#1da1f2' }} variant="contained" startIcon={<Twitter />}>Tweet</Button>
                </a>
            </Box>
            <Box sx={{ p: 1 }}>
                <Button variant="contained" color="tertiary" disabled={disabled}  size="small" title="Copy Link" onClick={copyLink} startIcon={<ContentCopy />}>Copy Link</Button>
            </Box>

            {canShare && (
                <Box sx={{ p: 1 }}>
                    <Button variant="contained" color="action" disabled={disabled}  size="small" title="Share" onClick={share} startIcon={<IosShareIcon />}>Share</Button>
                    
                </Box>
            )}
        </>
    );

}