import * as React from 'react';
import {
    Box,
    Button} from "@mui/material";
import { PersonOutline } from '@mui/icons-material';
import WriterAvatarEditor from './avatar-editor.component';
import WriterProfileContext from '../../../contexts/writer-profile/writer-profile.context';

export default function WriterAvatar(props) {

    const [profilePicEditMode, setProfilePicEditMode] = React.useState();
    const [writerProfile, ] = React.useContext(WriterProfileContext);
    
    if(!writerProfile) return null;
    const { profileImage } = writerProfile;
    return (
        <Box sx={{display:'flex', flexWrap:'wrap',width:'250px',justifyContent:'center',p:1}}>
                {!profileImage && (
                    <PersonOutline sx={{ fontSize: '225px', color: '#CCC' }} style={{ width: '250px' }}/>)}
                {profileImage && (
                    <img alt="profile image" src={'https://assets.meetnewbooks.com/' + profileImage} style={{ width: '250px', border: '1px solid #999', borderRadius: '8px' }} />)}

                {profilePicEditMode && (<WriterAvatarEditor setEditMode={setProfilePicEditMode} />)}
                <Box>
                <Button variant='contained' sx={{m:1}} onClick={() => setProfilePicEditMode(true)}>{profileImage?'Edit Profile Pic':'Add Profile pic'}</Button>
                </Box>
        </Box>

    );

}


