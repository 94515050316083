import React, { Suspense } from 'react';





import { Button, Snackbar } from "@mui/material";
import HeaderMenu from '../header/header-menu-component';

const DialogHost = React.lazy(() => import('./dialog-host-component'));

class DialogHostWrapper extends React.Component {
  constructor() {
    super();

    this.state = {
      ready: false,
      performingAction: false,
      user: null,
     // userData: null,
      aboutDialog: {
        open: false,
      },
      signUpDialog: {
        open: false,
      },
      signInDialog: {
        open: false,
      },
      settingsDialog: {
        open: false,
      },
      deleteAccountDialog: {
        open: false,
      },
      signOutDialog: {
        open: false,
      },
      snackbar: {
        autoHideDuration: 0,
        message: "",
        open: false,
      },
    }
  }
  // resetState = (callback) => {
  //   this.setState(
  //     {
  //       ready: true,
  //       user: null,
  //      // userData: null,
  //       roles: [],
  //     },
  //     callback
  //   );
  // };
  
  sendEvent(dialogId){
    if(dialogId && window.gtag){
      window.gtag('event', 'dialog',{
        dialogId: dialogId
      })
    }
  }
  
  openDialog = (dialogId, callback) => {
    var dialog = this.state[dialogId];
    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = true;
    this.setState({ dialog }, callback);
    this.setState({ dialogOpened: true });
    this.sendEvent(dialogId);
  };


  
  closeDialog = (dialogId, callback) => {
    const dialog = this.state[dialogId];

    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = false;


    this.setState({ dialog }, callback);
    this.setState({ dialogOpened: false });

  };

  closeAllDialogs = (callback) => {
    this.setState(
      {
        aboutDialog: {
          open: false,
        },

        signUpDialog: {
          open: false,
        },

        signInDialog: {
          open: false,
        },

        settingsDialog: {
          open: false,
        },

        deleteAccountDialog: {
          open: false,
        },

        signOutDialog: {
          open: false,
        },
      },
      callback
    );
  };
  deleteAccount = (message) => {
    
    this.closeAllDialogs(() => {
      this.openSnackbar(message);
    });
    this.props.setCurrentUser(null);
  }
  /*deleteAccount = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .deleteAccount()
          .then(() => {
            this.closeAllDialogs(() => {
              this.openSnackbar("Deleted account");
            });
            this.props.setCurrentUser(null);
          })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };*/

  signOut =(message)=>{
    this.closeAllDialogs(() => {
      this.openSnackbar(message);
      this.props.setCurrentUser(null)
    });
  }
  /*signOut = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .signOut()
          .then(() => {
            this.closeAllDialogs(() => {
              this.openSnackbar("Signed out");
              this.props.setCurrentUser(null)
            });
          })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
              currentUser:null
            });
          });
      }
    );
  };*/

  openSnackbar = (message, autoHideDuration = 2, callback) => {
    console.log("Called openSnackbar with"+message);
    this.setState(
      {
        snackbar: {
          // autoHideDuration: readingTime(message).time * autoHideDuration,
          autoHideDuration: 2000 * autoHideDuration,
          message,
          open: true,
        },
      },
      () => {
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  };
  
  
  closeSnackbar = (clearMessage = false) => {
    const { snackbar } = this.state;

    this.setState({
      snackbar: {
        message: clearMessage ? "" : snackbar.message,
        open: false,
      },
    });
  };
  render() {

    const {
      performingAction,



    } = this.state;

    const {
      aboutDialog,
      signInDialog,
      signUpDialog,
      settingsDialog,
      deleteAccountDialog,
      signOutDialog,
    } = this.state;
    const { snackbar } = this.state;
    if(!window.openSnackbar){
      window.openSnackbar=this.openSnackbar.bind(this);
    }
  
    if(!window.signInDialog) {
      window.signInDialog = function () {
        this.openDialog("signInDialog")
      }.bind(this);
    }
    if(!window.signUpDialog) {
      window.signUpDialog = function () {
        this.openDialog("signUpDialog")
      }.bind(this);
    }

    //don't render so we can lazy load when actually needed

   
    //don't render so we can lazy load when actually needed
    return (
      <>
        {this.state.dialogOpened && (
          <Suspense fallback={<span/>}>
            <DialogHost
              performingAction={performingAction}
              user={this.props.user}
              openSnackbar={this.openSnackbar}
              dialogs={{
                aboutDialog: {
                  dialogProps: {
                    open: aboutDialog.open,

                    onClose: () => this.closeDialog("aboutDialog"),
                  },
                },

                signUpDialog: {
                  dialogProps: {
                    open: signUpDialog.open,
                    onClose: (callback) => {
                      this.closeDialog("signUpDialog");

                      if (callback && typeof callback === "function") {
                        callback();
                      }
                    },
                  },
                },

                signInDialog: {
                  dialogProps: {
                    open: signInDialog.open,

                    onClose: (callback) => {
                      this.closeDialog("signInDialog");

                      if (callback && typeof callback === "function") {
                        callback();
                      }
                    },
                    onRegisterClick: () => {
                      this.closeDialog("signInDialog");
                      this.openDialog("signUpDialog");
                    }

                  },
                },

                settingsDialog: {
                  dialogProps: {
                    open: settingsDialog.open,

                    onClose: () => this.closeDialog("settingsDialog"),
                  },

                  props: {
                    onDeleteAccountClick: () =>
                      this.openDialog("deleteAccountDialog"),
                  },
                },

                deleteAccountDialog: {
                  dialogProps: {
                    open: deleteAccountDialog.open,

                    onClose: () => this.closeDialog("deleteAccountDialog"),
                  },

                  props: {
                    deleteAccountCallback: this.deleteAccount,
                  },
                },

                signOutDialog: {
                  dialogProps: {
                    open: signOutDialog.open,

                    onClose: () => this.closeDialog("signOutDialog"),
                  },

                  props: {
                    title: "Sign out?",
                    contentText:
                      "Please confirm you want to sign out",
                    dismissiveAction: (
                      <Button
                        color="primary"
                        onClick={() => this.closeDialog("signOutDialog")}
                      >
                        Stay signed in
                      </Button>
                    ),
                    confirmingAction:this.signOut
                    // confirmingAction: (
                    //   <Button
                    //     color="primary"
                    //     disabled={performingAction}
                    //     variant="contained"
                    //     onClick={signOut}
                    //   >
                    //     Sign Out
                    //   </Button>
                    // ),
                  },
                },
              }}
            />
          </Suspense>
        )}
        {snackbar.open && (
          <Suspense>
            <Snackbar
              autoHideDuration={snackbar.autoHideDuration}
              message={snackbar.message}
              open={snackbar.open}
              onClose={this.closeSnackbar}
            />
          </Suspense>
        )}

        <HeaderMenu user={this.props.user} userData={this.props.userData} onSignUpClick={() => this.openDialog("signUpDialog")}
          onAboutClick={() => this.openDialog("aboutDialog")}
          onSignInClick={() => this.openDialog("signInDialog")}
          onSettingsClick={() => this.openDialog("settingsDialog")}
          onSignOutClick={() => this.openDialog("signOutDialog")} />  



      </>
    );
  }
}

export default DialogHostWrapper;
