import * as React from 'react';
import {
  Typography,
  Box,
  Button,
  TextField,
  CircularProgress} from "@mui/material";
import 'react-quill/dist/quill.snow.css';
import { fetch as crossFetch } from 'cross-fetch';
import authentication from '../../../services/authentication';
import WriterProfileContext from '../../../contexts/writer-profile/writer-profile.context';
import HtmlEditor from '../html-editor.component';
import { useHistory } from 'react-router';
import CurrentBookContext from '../../../contexts/current-book/current-book.context';
import BooksLoadingContext from '../../../contexts/books-loading/books-loading.context';
import TagsAutoComplete from './promote/tags.component';


export default function BookDetailEdit(props) {
  const [book, setBook] =  React.useContext(CurrentBookContext);
  const [writerProfile,] =  React.useContext(WriterProfileContext);
  const [,setBooksLoading]=React.useContext(BooksLoadingContext);

  const textFieldStyle = { width: '100%' };
  const textFieldBoxStyle = { padding: '.5rem 0' , marginBottom:'1rem'}

  
  const [deleteClicked, setDeleteClicked] = React.useState(false)
  const {setEditing} = props;
  

  const [synopsis, setSynopsis] = React.useState(book && book.synopsis?book.synopsis:'');
  const [synopsisLength, setSynopsisLength] = React.useState(book && book.synopsisLength?book.synopsisLength:'');
  const [tags,setTags]= React.useState([]);

  const history = useHistory();



  const cancelClicked = () => {
    setBook(null);
    history.push("/mybooks");

  }
  const MAX_CHARS = 2000;
  const MIN_CHARS=200;
   
  const saveBook = () => {

    if(synopsisLength>MAX_CHARS){
      window.openSnackbar("The Book Synopsis cannot be more than "+MAX_CHARS+" characters");
      return;
    }
    if(synopsisLength<MIN_CHARS){
      window.openSnackbar("The Book Synopsis cannot be less than "+MIN_CHARS+" characters");
      return;
    }

   
    const data = new URLSearchParams();

    for (const pair of new FormData(document.getElementById("bookForm"))) {
      data.append(pair[0], pair[1]);
    }


    data.append("synopsis", synopsis);
    data.append("tags", tags?tags.join("\n"):null);
  /*  data.append("ebook", getFormats("box-ebook"));
    data.append("paperback", getFormats("box-paperback"));
    data.append("hardcover", getFormats("box-hardcover"));*/
    setBooksLoading(true);
    authentication.getIdToken().then((token) => {
      data.append("idToken", token);
     // data.append("delete", Boolean(deleteClicked));

      crossFetch(process.env.REACT_APP_URLBASE + "saveWriterBook", {
        method: 'post',
        body: data
      }).then(res => {
        if (res.status >= 400) {
          window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
        }
        return res.json();
      }).then((t) => {
        setBooksLoading(false);
        var error = false;
        if (!t || !t.bookId) {
          window.openSnackbar("Sorry, we're having trouble saving your changes now. Please refresh your browser or try again later");
          error = true;
        }
        if (!error) {
          console.log("Window. window.openSnackbar"+ window.openSnackbar)
          if (deleteClicked) {
            
            window.openSnackbar("Your book has been deleted");
            setDeleteClicked(false);
            cancelClicked();
          }
          else if (window.openSnackbar) {
            window.openSnackbar("Your changes have been saved");
            setEditing(false)
          }
          setBook(t);
          //setWriterProfile(writerProfile);
        }

      }
      ).catch(function (e) {
        window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
        console.log("Got an error back" + e)
        setBooksLoading(false);
      });
    });
  }
  const fetchBook = (token, bookId) => {
    const url = process.env.REACT_APP_URLBASE + "getWriterBook?idToken=" + token + "&bookId=" + bookId;
    crossFetch(url)
      .then(res => {
        return res.json();
      })
      .then((data) => {
        setBook(data);
        if(data.tags){
          setTags(data.tags.split("\n"));
        }
      }
      ).catch(function (e) {
        console.log("Got an error back" + e)
        window.gtag('event', 'exception', {
          'description': e + ' url ' + url,
          'fatal': false   // set to true if the error is fatal

        });
        window.openSnackbar("Sorry, something went wrong. Please refresh your browser and try again")
      })
  }

  
 
  React.useEffect(() => {

    if (book.bookId) {
      authentication.getIdToken().then((token) => {
        fetchBook(token, book.bookId)
      });
    }
    else {
      //default the author name
      
      setBook({
        authorName: writerProfile.fullName
      })

    }
   /* else {
      setBook({
        "bookId":null,
        "ebook": {},
        "paperback": {},
        "hardcover": {}
      });

    }*/
  }, [book.bookId])
  console.log("add-edit-book:"+ book.bookId);

  if (!book) return <Box><CircularProgress /></Box>

  const buttonsBar = <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
    <Button variant="outlined" onClick={() => setEditing(false)}>Cancel</Button> &nbsp;

    <Button color="action" variant="contained" onClick={saveBook}>Save</Button>
  </Box>;

  return <>
       {buttonsBar}
      <Box sx={{ maxWidth: 'md' ,margin:'auto'}}>
    <form id="bookForm">
      <input type="hidden" name="bookId" value={book.bookId?book.bookId:''} />
      <Box sx={textFieldBoxStyle}>
        <TextField
          size="small"
          sx={textFieldStyle}
          label={requiredLabel('Title')}
          type="text"
          id="name-input"
          helperText="The title of your book"
          name="title" 
          defaultValue={book.title || ''}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box sx={textFieldBoxStyle}>
        <TextField
          size="small"
          sx={textFieldStyle}
          label={'Series Name'}
          type="text"
          helperText='If this book is part of a series, enter the series name'
          name="series" defaultValue={book.series?book.series:''}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box sx={textFieldBoxStyle}>
        <TextField
          size="small"
          sx={{ width: '5rem' }}
          label={'# in Series'}
          type="number" InputLabelProps={{ shrink: true }}
          name="bookSequence" defaultValue={book.bookSequence?book.bookSequence:''}

        />
      </Box>
      <Box sx={textFieldBoxStyle}>

        <TextField
          size="small"
          sx={textFieldStyle}
          type="text"
          id="name-input" label={requiredLabel("Author")}
          InputLabelProps={{ shrink: true }}
          name="authorName" defaultValue={book.authorName || writerProfile.fullName}

        />
      </Box>
      <Box sx={textFieldBoxStyle}>
      <Typography variant="caption">(Choose upto 5 tags from the list, or enter your own)</Typography>
        <TagsAutoComplete helperText="Start Typing. Hit Enter to insert." onChange={setTags} value={tags} label="Tags"/>
      </Box>
      <Typography sx={{color:'#333'}}>{requiredLabel("Synopsis")}</Typography>
      
      <HtmlEditor defaultValue={book.synopsis} setHtml={setSynopsis} setTotalChars={setSynopsisLength} maxChars={MAX_CHARS}/>
    </form>
   </Box>
   {buttonsBar}
  </>
}

function requiredLabel(label) {
  return <span>{label} <span style={{ color: 'red' }}>*</span></span>;
}
