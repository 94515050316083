import { Button, Box, Dialog, DialogActions, DialogTitle, Slider, Typography, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { fetch as crossFetch } from 'cross-fetch';
import authentication from '../../../../services/authentication';
import { DialogContent } from '@mui/material';
import { useTheme } from '@mui/material'
import { useMediaQuery } from "@mui/material";
import CurrentBookContext from '../../../../contexts/current-book/current-book.context';
import Dropzone from 'react-dropzone';

const urlBase = process.env.REACT_APP_URLBASE;

export default function BookCoverEditor(props) {

    const [book, setBook] = React.useContext(CurrentBookContext)

    const { setEditMode } = props;

    const defaultSelectedFile = book.coverImage ? urlBase + "profileImage?objectName=" + book.coverImage : null;
    const [selectedFile, setSelectedFile] = React.useState(defaultSelectedFile);

    const [busy, setBusy] = React.useState();
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState(0);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const editorRef = React.useRef(null);



    const onSave = (deletePic) => {
        setBusy(true);
        const canvasScaled = editorRef.current.getImageScaledToCanvas();
        const imageData = canvasScaled.toDataURL();
        authentication.getIdToken().then((token) => {
            const data = new FormData();
            data.append("file", imageData);
            data.append("idToken", token);
            data.append("delete", deletePic);
            if (book && book.bookId) {
                data.append("bookId", book.bookId);
            }


            crossFetch(urlBase + "saveWriterBookCover", {
                method: 'post',
                body: data
            }).then(res => {
                if (res.status >= 400) {
                    window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
                }
                return res.json();
            }).then((t) => {
                setBusy(false);

                var error = t.bookId === null;

                if (!error && deletePic && t.coverImage) {
                    window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
                    error = true;
                }
                if (!error && !deletePic && !t.bookId) {
                    console.log("Error ")
                    window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
                    error = true;
                }
                if (!error) {

                    if (window.openSnackbar) {
                        window.openSnackbar("Your changes have been saved");
                    }

                    setEditMode(false);
                    setBook(t);
                }

            }
            ).catch(function (e) {
                window.openSnackbar("Sorry, we're having trouble  saving your changes now. Please refresh your browser or try again later");
                console.log("Got an error back" + e)
                setBusy(false);
            });
        });
    };




    // React.useEffect(()=>{
    //     setSelectedFile( writerProfile.imageUrl);
    // },[])
    console.log("busy" + busy);
    console.log("selectedFile" + selectedFile);


    return (
        <Dialog fullScreen={isSmall} maxWidth={isSmall ? 'xl' : 'md'} open={true} sx={{ p: 1 }}>
            <DialogTitle>Book Cover</DialogTitle>
            <DialogContent>
                {busy && (<Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
                )}

                <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>


                    {!selectedFile && <Typography variant="caption" component="div" style={{ width: '100%', textAlign: 'center' }}>Drag a file to the box below, or use the upload button</Typography>}
                    <Dropzone
                        onDrop={(e) => setSelectedFile(e[0])}
                        noClick
                        noKeyboard
                    >
                        {({ getRootProps, getInputProps }) => (<div {...getRootProps()}>
                            <AvatarEditor width={315} height={500} image={selectedFile} crossOrigin="anonymous"
                                border={2} borderRadius={0}
                                color={[55, 55, 55, 0.6]} // RGBA
                                scale={scale}
                                rotate={rotation}
                                ref={editorRef}
                            />

                        </div>)}
                    </Dropzone>
                    <>

                        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'flex-start', p: 2 }}>

                            <Box sx={{ width: '100%', maxWidth: '250px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', p: 1 }}>
                                <Typography>  Zoom:</Typography>

                                <Slider
                                    aria-label="Zoom" defaultValue={1} step={0.01} onChange={(e) => {
                                        setScale(parseFloat(e.target.value));
                                    }}                                 //marks
                                    min={0.1} max={2} valueLabelDisplay="auto"
                                />
                            </Box>
                            <Box sx={{ width: '100%', maxWidth: '250px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', p: 1 }}>
                                <Typography>Rotate:</Typography>
                                <Slider aria-label="Rotate"
                                    defaultValue={0} step={1} onChange={(e) => {
                                        setRotation(parseFloat(e.target.value))

                                    }}
                                    //marks
                                    min={-180} max={180} valueLabelDisplay="auto"
                                />
                            </Box>
                        </Box>
                    </>
                    <Box sx={{ p: 1 }}>
                        <input
                            id="cover-input" type="file"
                            hidden accept="image/*"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                        />

                        <label htmlFor="cover-input">
                            <Button
                                // sx={!selectedFile ? { height: '200px' } : {}}
                                component="span"
                                color={selectedFile ? "primary" : "action"}
                                variant={selectedFile ? "outlined" : "contained"}
                            >
                                Click to Upload a new Cover
                            </Button>

                        </label>
                    </Box>
                    {selectedFile && (<Box sx={{ p: 1 }}>
                        <Button
                            color="primary" component="span"
                            onClick={() => onSave(true)}
                            variant="outlined">Delete Cover </Button>
                    </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setEditMode(false)}>Cancel</Button>
                <Button disabled={!Boolean(selectedFile)} variant="contained" onClick={() => onSave(false)}>Save Changes</Button>

            </DialogActions>
        </Dialog>)
}
