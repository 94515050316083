import React, { Suspense } from "react";
import { CircularProgress, Container } from "@mui/material";

import Dashboard from "../../components/default/dashboard.component";
import CurrentTabContext from "../../contexts/current-tab/current-tab.context";

const Landing = React.lazy(() =>
  import("../../components/default/landing.component")
);

const HomePage = (props) => {
  //const params = useParams();
  const { action, tab } = props;
  const [, setCurrentTab] = React.useContext(CurrentTabContext);

  const rootStyle = {
    backgroundColor: "#fff",
    position: "relative",
    minHeight: "95vh",
    maxWidth: "lg",
  };

  React.useEffect(() => {
    setCurrentTab(tab);
  }, [tab, setCurrentTab]);

  return (
    <Container sx={rootStyle} disableGutters>
      <Suspense
        fallback={
          <div style={{ width: "50px", margin: "auto" }}>
            <CircularProgress />
          </div>
        }
      >
        {!action && <Landing />}
        {action && <Dashboard {...props} />}
      </Suspense>
      <Container maxWidth={false} sx={{ padding: "20px" }}></Container>
    </Container>
  );
};
export default HomePage;
