import * as React from 'react';
import {
    Box,
    Button} from "@mui/material";
import WriterProfileContext from '../../../../contexts/writer-profile/writer-profile.context';
import BookCoverEditor from './book-cover-editor.component';
export default function BookCover({book}) {

    const [coverEditMode, setCoverEditMode] = React.useState();

    const published = Boolean(book && book.publishStatus==='published');
    return (
        <>
            <Box sx={{ p: 0, m: '0 0 1rem 0', width: '100%',display:'flex',flexWrap:'nowrap', justifyContent:'center', alignItems:'center'}}>

                {!book.coverImage && (
                    <Button onClick={() => setCoverEditMode(true)} >
                    <img src={'https://assets.meetnewbooks.com/nocoveruploaded.png'} style={{ width: '130px', opacity:'.8',height:'190px', border: '1px solid #999', boxShadow: '8px 8px 8px 0 rgb(0 0 0 / 60%)' }} />
                    </Button> )}
                {book.coverImage && (
                    <img src={'https://assets.meetnewbooks.com/' + book.coverImage} style={{ width: '130px', height:'190px', border: '1px solid #999', boxShadow: '8px 8px 8px 0 rgb(0 0 0 / 60%)' }} />)}

                {coverEditMode && (<BookCoverEditor setEditMode={setCoverEditMode}  />)}

                <Box sx={{ p: '0 0 0 1rem'}}>
                    <Button disabled={published}  variant='outlined' onClick={() => setCoverEditMode(true)}>Add/Edit Cover</Button>
                </Box>
            </Box>
           

        </>

    );

}


