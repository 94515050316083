import React from "react";
import { Router } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import { createBrowserHistory } from "history";
// import { analytics } from './firebase';

import "./index.css";
import App from "./App";
import { hydrate, render } from "react-dom";

// ReactGA.initialize("UA-191772426-1", {
//   siteSpeedSampleRate: 100,
//   debug:true
// });

const browserHistory = createBrowserHistory();
browserHistory.listen((location) => {
  // console.log("browserHistory.listen", location.pathname);
  if (window.gtag) {
    //get the fist part of the path

    let path = null;
    try {
      path = location.pathname.split("/")[1];
    } catch (error) {
      console.log("error", error);
    }

    path = path ? path : location.pathname;

    window.gtag("event", path, {
      fullpath: location.pathname + location.search,
    });
  }
});

const rootElement = document.getElementById("root");

const myApp = (
  <Router history={browserHistory}>
    <StyledEngineProvider injectFirst>
      <App browserHistory={browserHistory} />
    </StyledEngineProvider>
  </Router>
);

if (rootElement.hasChildNodes()) {
  hydrate(myApp, rootElement);
} else {
  render(myApp, rootElement);
}

// ReactDOM.render(
//   myApp
//   ,
//   document.getElementById('root')
// );
