import * as React from "react";
import { Typography, Box, TextField } from "@mui/material";

export default function WriterProfileLink({ profileLinks, link }) {
  const [value, setValue] = React.useState(link.value || "");

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="formLabel">{link.linkTypeDisplay}:</Typography>
      <Typography>
        {link.prefix}
        {value}
      </Typography>
      <TextField
        name={link.linkType}
        size="small"
        defaultValue={link.value ? link.value : ""}
        inputProps={{ maxLength: 100 }}
        onChange={(e) => {
          const linkType = e.currentTarget.name;
          for (let x = 0; x < profileLinks.length; x++) {
            if (profileLinks[x].linkType === linkType) {
              profileLinks[x].value = e.currentTarget.value;
              setValue(e.currentTarget.value);
              break;
            }
          }

          // profileLinks[e.currentTarget.linkType]
        }}
        placeholder={link.helpText}
      />
    </Box>
  );
}
